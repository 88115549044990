import { useMutation, useQuery, keepPreviousData } from '@tanstack/react-query'
import { addChainIdToApiReq } from '@/stores/tokenStore/util'
import {
  favouriteTokens,
  getAllTokens,
  getBinanceAlphaTokens,
  getFavouriteTokens,
  getMemesOverview,
  getPoolToken,
  getSelectedTokens,
  getTokenByName,
  getTokenKLine,
  getTokenPrices,
  getTokenSearch,
  getTrendingTokens,
  IntervalType
} from 'api'

// --------------------------------------------------------
export const useTrendingTokens = (params?: { chain?: string }) => {
  return useQuery({
    // staleTime: 30_000,
    refetchInterval: 30_000,
    queryKey: ['market-trending-tokens', params],
    queryFn: async () => {
      const data = await getTrendingTokens(params)
      return addChainIdToApiReq(data)
    },
    placeholderData: keepPreviousData
  })
}

// --------------------------------------------------------
export const useSelectedTokens = () => {
  return useQuery({
    staleTime: 30_000,
    queryKey: ['market-selected-tokens'],
    queryFn: async () => {
      const data = await getSelectedTokens()
      return addChainIdToApiReq(data)
    }
  })
}

// --------------------------------------------------------
export const useTokens = () => {
  return useQuery({
    staleTime: 60_000,
    queryKey: ['market-all-tokens'],
    queryFn: getAllTokens
  })
}

// --------------------------------------------------------
export const useTokenByName = (tokenName: string) => {
  return useQuery({
    enabled: !!tokenName?.trim(),
    staleTime: 30_000,
    queryKey: ['use-token-by-name', tokenName],
    queryFn: () => getTokenByName(tokenName)
  })
}

// --------------------------------------------------------
export const useTokenSearch = (content: string, chain: string) => {
  return useQuery({
    staleTime: 30_000,
    queryKey: ['use-token-search', content, chain],
    // queryFn: () => getTokenSearch(content, chain)
    queryFn: async () => {
      const res = await getTokenSearch(content, chain)
      return addChainIdToApiReq(res)
    }
  })
}

// --------------------------------------------------------
export const useTokenPrices = (
  tokenName: string,
  interval: IntervalType,
  isTestnet: boolean
) => {
  return useQuery({
    staleTime: 10_000,
    queryKey: ['use-token-prices', tokenName, interval],
    queryFn: () => {
      if (isTestnet) {
        return undefined
      }
      return getTokenPrices(tokenName, interval)
    }
  })
}

export const useTokenPricesV2 = (
  tokenName: string,
  interval: IntervalType,
  isTestnet: boolean
) => {
  return useQuery({
    staleTime: 10_000,
    queryKey: ['use-token-prices-v2', tokenName, interval],
    queryFn: () => {
      if (isTestnet) {
        return undefined
      }
      return getTokenKLine(tokenName, interval)
    }
  })
}

export const useTokenPool = (tokenName: string, isTestnet: boolean) => {
  return useQuery({
    staleTime: 0,
    refetchInterval: 5_000,
    queryKey: ['use-token-pool', tokenName, isTestnet],
    queryFn: () => {
      if (isTestnet) {
        return []
      }
      return getPoolToken(tokenName)
    }
  })
}

// --------------------------------------------------------
export const useMemesOverview = (chain: string, address: string) => {
  return useQuery({
    enabled: !!chain,
    staleTime: 30_000,
    queryKey: ['use-memes-overview', chain, address],
    queryFn: () => getMemesOverview(chain, address)
  })
}

// --------------------------------------------------------

export const useGetFavouriteTokens = (props?: {
  refetchInterval: number | false
}) => {
  const refetchInterval = props?.refetchInterval ?? 20_000
  return useQuery({
    // staleTime: 3000,
    refetchInterval,
    queryKey: ['use-get-favourite-tokens'],
    queryFn: async () => {
      const data = await getFavouriteTokens()
      return addChainIdToApiReq(data)
    }
  })
}

export const useGetBinanceAlphaTokens = () => {
  return useQuery({
    // staleTime: 3000,
    refetchInterval: 20_000,
    queryKey: ['use-get-binance-alpha-tokens'],
    queryFn: async () => {
      const data = await getBinanceAlphaTokens()
      return addChainIdToApiReq(data)
    }
  })
}

export const useFavouriteTokens = () => {
  return useMutation({
    mutationKey: ['use-favourite-tokens'],
    mutationFn: favouriteTokens
  })
}
