import { useEffect, useState } from 'react'
import { reaction } from 'mobx'
import { Config } from '@wagmi/core'
import { IWeb3ChainType } from '@/proviers/web3Provider/type'
import chainsStore from '..'
import { MarketTabsEnum } from '@/pages/market/components/detail/Menus'

const useChainsStore = () => {
  const [evmConfig, setEvmConfig] = useState(() => chainsStore.evmConfig)
  const [evmChains, setEvmChains] = useState(() => chainsStore.evmChains)
  const [renderChains, setRenderChains] = useState(
    () => chainsStore.renderChains
  )
  const [allChains, setAllChains] = useState(() => chainsStore.allChains)
  const [okxChains, setOkxChains] = useState(() => chainsStore.okxChains)
  const [chains, setChains] = useState(() => chainsStore.chains)
  const [marketSelectChains, setMarketSelectChains] = useState(
    () => chainsStore.chains
  )
  const [supportSwapChains, setSupportSwapChains] = useState(
    () => chainsStore.supportSwapChains
  )
  const [supportGiftChains, setSupportGiftChains] = useState(
    () => chainsStore.supportGiftChains
  )
  const [unSupportHistoryChains, setUnSupportHistoryChains] = useState(
    () => chainsStore.unSupportHistoryChains
  )

  useEffect(() => {
    const supportGiftChainsReaction = reaction(
      () => chainsStore.supportGiftChains,
      (v) => setSupportGiftChains(v)
    )
    const unSupportHistoryChainsReaction = reaction(
      () => chainsStore.unSupportHistoryChains,
      (v) => setUnSupportHistoryChains(v)
    )
    const evmConfigReaction = reaction(
      () => chainsStore.evmConfig,
      (v) => setEvmConfig(v)
    )
    const supportSwapChainsReaction = reaction(
      () => chainsStore.supportSwapChains,
      (v) => setSupportSwapChains(v)
    )
    const evmChainsReaction = reaction(
      () => chainsStore.evmChains,
      (v) => setEvmChains(v)
    )
    const renderChainsReaction = reaction(
      () => chainsStore.renderChains,
      (v) => setRenderChains(v)
    )
    const allChainsReaction = reaction(
      () => chainsStore.allChains,
      (v) => setAllChains(v)
    )
    const okxChainsReaction = reaction(
      () => chainsStore.okxChains,
      (v) => setOkxChains(v)
    )
    const chainsReaction = reaction(
      () => chainsStore.chains,
      (v) => setChains(v)
    )
    const marketSelectChainsReaction = reaction(
      () => chainsStore.marketSelectChains,
      (v) => setMarketSelectChains(v)
    )

    // chainsAction

    return () => {
      supportSwapChainsReaction()
      marketSelectChainsReaction()
      supportGiftChainsReaction()
      unSupportHistoryChainsReaction()
      chainsReaction()
      okxChainsReaction()
      allChainsReaction()
      evmConfigReaction()
      evmChainsReaction()
      renderChainsReaction()
    }
  }, [])

  const showMenus = (chainInfo: IWeb3ChainType | undefined) => {
    const supportMenu = [
      MarketTabsEnum.SEND,
      MarketTabsEnum.RECEIVE,
      MarketTabsEnum.REFER,
      MarketTabsEnum.CARD // defaulte support
    ]
    if (!chainInfo) {
      const defaultTabsShow = [
        MarketTabsEnum.SEND,
        MarketTabsEnum.RECEIVE,
        MarketTabsEnum.CARD,
        MarketTabsEnum.SWAP,
        MarketTabsEnum.GIFT,
        MarketTabsEnum.REFER
      ]
      return defaultTabsShow
    }
    if (supportSwapChains?.find((chain) => chain.id === chainInfo.id)) {
      supportMenu.push(MarketTabsEnum.SWAP)
    }
    if (supportGiftChains?.find((chain) => chain.id === chainInfo.id)) {
      supportMenu.push(MarketTabsEnum.GIFT)
    }
    return supportMenu
  }

  return {
    evmChains,
    evmConfig,
    renderChains,
    allChains,
    chains: allChains,
    chainIds: chainsStore.chainIds,
    supportSwapChains: supportSwapChains,
    supportGiftChains,
    unSupportHistoryChains,
    setSupportSwapChains: (info: IWeb3ChainType[] | undefined) =>
      chainsStore.supportSwapChainsAction(info),
    setSupportGiftChains: (info: IWeb3ChainType[] | undefined) =>
      chainsStore.supportGiftChainsAction(info),
    setUnSupportHistoryChains: (info: IWeb3ChainType[] | undefined) =>
      chainsStore.unSupportHistoryChainsAction(info),
    setChains: (info: IWeb3ChainType[] | undefined) =>
      chainsStore.chainsAction(info),
    marketSelectChains,
    setMarketSelectChains: (info: IWeb3ChainType[] | undefined) =>
      chainsStore.marketSelectChainsAction(info),
    setAllChains: (info: IWeb3ChainType[] | undefined) =>
      chainsStore.allChainsAction(info),
    setEvmConfig: (info: Config<any, any> | undefined) =>
      chainsStore.evmConfigAction(info),
    setEvmChains: (info: IWeb3ChainType[] | undefined) =>
      chainsStore.evmChainsAction(info),
    setRenderChains: (info: IWeb3ChainType[] | undefined) =>
      chainsStore.renderChainsAction(info),
    showMenus
  }
}

export default useChainsStore
