import { IWeb3ChainType, Web3Type } from '@/proviers/web3Provider/type'
import { UserAddressType, UserType } from './type'
import { AssetsToken } from '../tokenStore/type/AssetsToken'
import configChains from '@/proviers/web3Provider/chains'

export const ExpreTime = 1000 * 60 * 60 * 1

export const initUserState = () => {
  const userState = localStorage.getItem('userState')
  if (!userState)
    return {
      tgId: undefined,
      userId: undefined,
      newUser: false,
      frozen: false,
      loginTime: undefined,
      tokenExpired: undefined,
      setTradePassword: false
    }
  return JSON.parse(userState)
}

export const initBiometry = () => ({
  access_granted: false,
  access_requested: false,
  available: false,
  device_id: '',
  token_saved: false,
  platform: ''
})

export const initUserInfo = () => {
  const userInfo = localStorage.getItem('user')
  if (!userInfo || userInfo === 'null') return {}
  return JSON.parse(userInfo)
}

export const userAddressList = (user: UserType) => {
  return {
    bitcoinP2pkhAddress: user?.bitcoinP2pkhAddressMain || '',
    bitcoinP2trAddress: user?.bitcoinP2trAddressMain || '',
    bitcoinP2shAddress: user?.bitcoinP2shAddressMain || '',
    bitcoinP2wpkhAddress: user?.bitcoinP2wpkhAddressMain || '',
    ethereumAddress: user?.ethereumAddress || '',
    solanaAddress: user?.solanaAddress || '',
    tronAddress: user?.tronAddress || '',
    tonAddressTest: user?.tonAddressTest || '',
    tonAddress: user?.tonAddress || '',
    tonPublicKey: user?.tonPublicKey || '',
    suiAddress: user?.suiAddress || ''
  } as UserAddressType
}

export const userChainAddressList = (user: UserType, chainId: number) => {
  if (chainId === configChains.btc.id) {
    return user.bitcoinP2pkhAddressMain
  }
  if (chainId === configChains.solana.id) {
    return user.solanaAddress
  }
  if (chainId === configChains.tron.id) {
    return user.tronAddress
  }
  if (chainId === configChains.ton.id) {
    return user.tonAddress
  }
  if (chainId === configChains.tonTestnet.id) {
    return user.tonAddressTest
  }
  if (chainId === configChains.cosmos.id) {
    return user.cosmosAddress
  }
  if (chainId === configChains.sui.id) {
    return user.suiAddress
  }
  if (chainId === configChains.doge.id) {
    return user.dogeAddress
  }
  return user.ethereumAddress
}

export const displayName = (user: UserType) => {
  if (!user) return ''
  if (user.tgName) {
    return user.tgName
  }
  if (user.nickname) {
    return user.nickname
  }
  if (user.username) {
    if (user.username?.includes('telegram@')) {
      return user.username.substring('telegram@'.length)
    }
  }
}

export const getBtcWallet = (user: UserType, btcWalletType: string) => {
  // !TODO  bitcoinP2wpkhAddress and bitcoinP2WpkhAddress, should toLowerCase;
  const type = btcWalletType.toLowerCase()
  switch (type) {
    case 'bitcoinp2pkh':
      return user?.bitcoinP2pkhAddressMain
    case 'bitcoinp2tr':
      return user?.bitcoinP2trAddressMain
    case 'bitcoinp2sh':
      return user?.bitcoinP2shAddressMain
    case 'bitcoinp2wpkh':
      return user?.bitcoinP2wpkhAddressMain
    default:
      return user?.bitcoinP2pkhAddressMain
  }
}

// export const authCheck = (biometryManager: any) => {
//   const reason = 'We need to authenticate you to continue'
//   const authPromise = new Promise((resolve, reject) => {
//     try {
//       biometryManager?.authenticate({reason},
//         () => resolve(true)
//       )
//     } catch(e) {
//       // ...
//     }
//   })

// }
