import { DexTransaction } from '@/constants/types'
import { UserType } from '@/hooks/useLoginInfo'
import { TON_EVENT_SEND_SUCCESS } from '@/utils/tonConnect/config'

export interface TradeToken {
  createdTime: string // lose
  updatedTime: string // lose
  id: number
  name: string
  displayName: string
  symbol: string
  imageUrl: string
  decimals: number
  chain: string
  isNative: boolean // lose
  isTomoji: boolean // lose
  priceUsd: string
  priceChangeH24: number
  volumeH24: string
  supportRango: boolean // lose
  groupId: string // lose
  address?: string
}

export interface TokenInfo {
  chain_id: number
  id: number | string
  image: string
  market_cap: number
  name: string
  price: number
  price_change_h24: number
  symbol: string
  contract?: string
}

export interface CustomListInfo {
  ID: number
  chain_id: number
  created_at: number
  decimals: number
  chain: string
  display_name: string
  image: string
  market_cap: number
  name: string
  price: number
  price_change_h24: number
  symbol: string
  token: string
  uid: number
  four_meme_token: {
    raise_token_address: string
    raise_token_symbol: string
    launch_on_pancake: boolean
  }
}

export interface WhiteListInfo {
  balance: string
  chain_id: number
  contract: string
  chain: string
  display_name: string
  decimals: number
  image: string
  is_native: boolean
  mercuryo_support: string
  name: string
  price: number
  ramp_support: string
  symbol: string // "ARB-ETH"
  four_meme_token: {
    raise_token_address: string
    raise_token_symbol: string
    launch_on_pancake: boolean
  }
}

export interface SolSendTx {
  rawTransaction: string
}

export type BTCNetworkType = 'MAINNET' | 'TESTNET' | 'SIGNET'
export type BTCNetworkAddressType = 'P2PKH' | 'P2WPKH' | 'P2TR' | 'P2SH'

export enum BTCNetworkTypeEnum {
  MAINNET = 'MAINNET',
  TESTNET = 'TESTNET',
  SIGNET = 'SIGNET'
}

export enum BTCNetworkAddressTypeEnum {
  P2PKH = 'P2PKH',
  P2WPKH = 'P2WPKH',
  P2TR = 'P2TR',
  P2SH = 'P2SH'
}

export interface BtcCreateSendBtcPsbt {
  networkType: BTCNetworkType
  addressType: BTCNetworkAddressType | undefined
  toAddress: string
  amount: string
}

export interface BtcSignPsbt {
  networkType: BTCNetworkType
  addressType: BTCNetworkAddressType
  autoFinalized: boolean
  psbtHex: string
}

export interface BtcPushTx {
  networkType: BTCNetworkType
  rawTransaction: string
}

export type BtcAddressType =
  | 'bitcoinP2pkhAddress'
  | 'bitcoinP2shAddress'
  | 'bitcoinP2trAddress'
  | 'bitcoinP2wpkhAddress'

export type ISwapTokensParams = {
  chain: number | undefined
  page: number | undefined
  size: number | undefined
  keyword: string | undefined
}

const mock = {
  // need
}

export type TrendingToken = {
  createdTime: string
  updatedTime: string
  id: number
  chainId: number
  name: string
  displayName: string
  symbol: string
  imageUrl: string
  decimals: number
  chain: string
  isNative: boolean
  isTomoji: boolean
  address: string
  priceUsd: string
  priceChangeH24: number
  volumeH24: string
  marketCapUsd: string
  poolAddress: string
  supportRango: boolean
  groupId: string
  liquidityUsd?: string
  fdvUsd?: string
  risk?: string | null
  fourMemeToken?: boolean
}

export type MarketConfigToken = TrendingToken & {
  chainId?: number
  fdvUsd: string
  liquidityUsd: string
  totalSupply: string
  isPoolBaseToken: string
  coingeckoCoinId: string
}

export type Result<TData = any> = {
  code: number
  message: string
  result: TData
}

export interface MemeOverview {
  address: string
  decimals: number
  symbol: string
  name: string
  extensions: MemeOverviewExtensions
  logoURI: string
  liquidity: number
  price: number
  supply: number
  mc: number
  history24hPrice: number
  priceChange24hPercent: number
  fdvUsd: number
  high24h: number
  low24h: number
}
export interface MemeOverviewExtensions {
  twitter: string
  website: string
  telegram: string
}

export interface PriceItem {
  timestamp: number
  open: number
  high: number
  low: number
  close: number
  volume: number
}

export interface WSSteamItem {
  cid: string
  rc: number
  da: LineWSItem | TradeWSItem[] | PriceWSItem | InfoWSItem
}

export interface LineWSItem {
  t: number
  o: number
  h: number
  l: number
  c: number
  v: number
}

export interface TradeWSItem {
  a: string
  aiu: string
  ao: string
  aoiu: string
  m: string
  p: number
  piu: number
  s: string
  t: number
  tos: string
  ts: string
  ty: string
}

export interface PriceWSItem {
  p: number
  t: number
}

export interface InfoWSItem {
  msg: string
}

export interface IUtxo {
  txid: string
  vout: number
  status: {
    confirmed: boolean
    block_height: number
    block_hash: string
    block_time: number
  }
  value: number
}

export interface IInitSeedExportRes {
  validTime: number
  expireTime: number
  keyId: string
}

export interface IFinishSeedExportRes {
  seed: string
  n: string
}

export type RequestType<T> = {
  result: T
  code: number
  message: string
}

export type UserAddressType = {
  ethereumAddress: string
  solanaAddress: string
  tonAddress: string
  tronAddress: string
  suiAddress: string
  bitcoinP2pkhAddress: string
  bitcoinP2trAddress: string
  bitcoinP2shAddress: string
  bitcoinP2wpkhAddress: string
  bitcoinP2pkhAddressTest: string
  bitcoinP2pkhPubKeyTest: string
  bitcoinP2pkhAddressMain: string
  bitcoinP2pkhPubKeyMain: string
  bitcoinP2trAddressTest: string
  bitcoinP2trPubKeyTest: string
  bitcoinP2trAddressMain: string
  bitcoinP2trPubKeyMain: string
  bitcoinP2shAddressTest: string
  bitcoinP2shPubKeyTest: string
  bitcoinP2shAddressMain: string
  bitcoinP2shPubKeyMain: string
  bitcoinP2wpkhAddressTest: string
  bitcoinP2wpkhPubKeyTest: string
  bitcoinP2wpkhAddressMain: string
  bitcoinP2wpkhPubKeyMain: string
}

/*
{
  "createdTime": "2024-06-06T06:57:18.000+00:00",
  "updatedTime": "2024-08-14T08:39:43.000+00:00",
  "id": 248,
  "name": "ETH-0xae7ab96520de3a18e5e111b5eaab095312d7fe84",
  "displayName": "STETH",
  "symbol": "steth",
  "imageUrl": "https://assets.coingecko.com/coins/images/13442/large/steth_logo.png?1696513206",
  "decimals": 18,
  "chain": "ETH",
  "isNative": false,
  "isTomoji": false,
  "address": "0xae7ab96520de3a18e5e111b5eaab095312d7fe84",
  "priceUsd": "2713.09",
  "priceChangeH24": 3.52,
  "volumeH24": "9806845.722639170000000000",
  "marketCapUsd": "26557362559.304900000000000000",
  "poolAddress": "0xdc24316b9ae028f1497c275eb9192a3ea0f67022",
  "supportRango": true,
  "groupId": "token-ETH-0xae7ab96520de3a18e5e111b5eaab095312d7fe84"
}
*/

export type CreateStarInvoiceReqType = {
  requestId: string
  telegramId: number
  chainId: number
  fromAddress: string // star use ""
  toAddress: string
  tokenContract: string // native use ""
  symbol: string
  decimals: number
  tokenPrice: number
  tokenAmount: string
  starAmount: number // must be int
  starUsdt: string
}

export type CreateStarInvoiceResType = {
  purchaseOrderId: number
  invoiceLink: string
}

export type getStarOrderListReqType = {
  telegramId: number
  page: number
  pageSize: number
}

export type getStarOrderListResType = {
  orderList: {
    chainId: string
    fromAddress: string
    toAddress: string
    tokenContract: string
    symbol: string
    decimals: string
    tokenPrice: number
    tokenAmount: string
    starAmount: string
    starUsdt: string
    orderStatus: string
    tx: string
    createdAt: string
  }[]
  hasMore: boolean
}

export type StableTokenType = {
  address: string
  chain: string
  chainId: string
  chainName: string
}

/* 
{
    "chainId": 10,
    "name": "OPTIMISM",
    "nativeCurrencyName": "OP_ETH",
    "nativeCurrencySymbol": "ETH",
    "nativeCurrencyDecimals": 18,
    "rpcUrls": [
        "https://opt-mainnet.g.alchemy.com/v2/oYu8Ai2M85roqxhvHxBMdLjqs8Kh-4qt",
        "https://optimism-mainnet.infura.io/v3/e42637ee1f664cad93e70bbf62196769",
        "https://rpc.ankr.com/optimism/c626b2acc7d5145c9b392e8aff82409e2c61444b24a36be4cceed74f29eedaba",
        "https://opt-mainnet.g.alchemy.com/v2/DR7Jtd4NSYTtAY7Heme8ml-j6oBCZgGO"
    ],
    "blockExplorerUrl": "https://optimistic.etherscan.io",
    "platformType": "EVM",
    "icon": "https://pub.tomo.inc/token/optimism.png"
}
*/

export type TomoChain = {
  chainId: number
  name: string
  chainName: string
  nativeCurrencyName: string
  nativeCurrencySymbol: string
  nativeCurrencyDecimals: number
  rpcUrls: string[]
  blockExplorerUrl: string
  isTestnet: boolean
  platformType:
    | 'EVM'
    | 'BTC'
    | 'SOLANA'
    | 'TON'
    | 'TRON'
    | 'SUI'
    | 'COSMOS'
    | 'DOGE'
  icon: string
  supportSwap: boolean
  supportGift: boolean
  supportHistory: boolean
}
export enum eCosmosChainId {
  cosmoshub = 'cosmoshub-4',
  babylonTestnet = 'bbn-test-3',
  nubitTestnet = 'nubit-alphatestnet-1'
}

export interface ICosmosSignDoc {
  bodyBytes: any
  authInfoBytes: any
  chainId: eCosmosChainId
  accountNumber: number
}

export interface ISignCosmosTxParams {
  signDoc: ICosmosSignDoc
  walletId?: string
}

export interface DogeTxRes {
  walletId: number
  rpcUrl: string
  from: string
  to: string
  fee: string
  amount: number
}

export type ChainGasParams = {
  chainId: number
  callData: string
  params: {
    from: string
    to: string
    value: string
  }
  addressList?: string[]
}

export type ChainGasResult = {
  gasLimit: string
  baseFee: string
  priorityFeeLow: string
  priorityFeeMedium: string
  priorityFeeHigh: string
}

export type ChainGasChainInfo = {
  chainName: string
  chainId: number
  chainIndex: number
  orderStatusSupport: boolean
  support: boolean
}

export type CenterSubmitParams = {
  platform: 1 | 2 | 3 | 4 //'ios' | 'android' | 'tg' | 'plugin'
  type: 1 | 2 | 3 // 1:swap 2:bridge 3:transfer
  // userId: string
  userId: number
  fromChainIndex: string
  toChainIndex: string
  fromTokenPrice: number
  toTokenPrice: number
  source?: 0 | 1 | 2 | 3 | 4 //1：okx 2: rango 3.ston-fi 4: jupiter 0: other
  slippage?: number
  fromAddress: string
  toAddress?: string // type = 3 required
  // fromChainIndex: number
  fromTokenAddress: string
  fromAmount: string
  // toChainIndex: number
  toTokenAddress: string
  toAmount: string
  sourceDex: string
  estimatedGas: string
  failReason: string
  tx: string
  callData: string
  extParams?: string[]
}

/**
status：
GENERATED((byte) 1, "生成订单"),
BROADCAST_FAILED((byte) 2, "广播失败"),
BROADCAST_SUCCEED((byte) 3, "广播成功"),
BROADCASTING((byte) 4, "交易广播中"),
SUCCEED((byte) 5, "订单交易成功"),
FAILED((byte) 6, "订单失败");
   * */

export enum TomoTxStatus {
  CREATE = 1,
  SEND_FAILED = 2,
  SEND_SUCCESS = 3,
  SENDING = 4,
  TX_SUCCESS = 5,
  TX_FAILED = 6
}

export type CenterSubmitResult = Omit<CenterSubmitParams, 'callData'> & {
  id: number
  finishAt: string
  createdAt: string
  updatedAt: string
  deletedAt: string
  status: TomoTxStatus
}

export type ApiParams = {
  user: UserType
  type: string
  params: DexTransaction
  init?: () => void
}

export interface PoolItem {
  address: string
  protocolFamily: string
  baseTokenAddress: string
  quoteTokenAddress: string
  baseToken: {
    createdTime: string
    updatedTime: string
    id: number
    name: string
    displayName: string
    symbol: string
    imageUrl: string
    decimals: number
    chain: string
    isNative: boolean
    isTomoji: boolean
    tomojiId: number
    address: string
    priceUsd: number
    priceChangeH24: number
    volumeH24: number
    marketCapUsd: number
    fdvUsd: number
    liquidityUsd: number
    totalSupply: number
    websiteUrl: string
    twitterUrl: string
    telegramUrl: string
    poolAddress: string
    isPoolBaseToken: boolean
    supportRango: boolean
    coingeckoCoinId: string
    risk: string
    forceSafe: boolean
    groupId: string
  }
  quoteToken: {
    createdTime: string
    updatedTime: string
    id: 0
    name: string
    displayName: string
    symbol: string
    imageUrl: string
    decimals: number
    chain: string
    isNative: boolean
    isTomoji: boolean
    tomojiId: number
    address: string
    priceUsd: number
    priceChangeH24: number
    volumeH24: number
    marketCapUsd: number
    fdvUsd: number
    liquidityUsd: number
    totalSupply: number
    websiteUrl: string
    twitterUrl: string
    telegramUrl: string
    poolAddress: string
    isPoolBaseToken: boolean
    supportRango: boolean
    coingeckoCoinId: string
    risk: string
    forceSafe: boolean
    groupId: string
  }
  baseTokenAmount: number
  baseTokenAmountInUsd: number
  quoteTokenAmount: number
  quoteTokenAmountInUsd: number
}
export interface IGetTokenRiskParams {
  chain: string
  address: string
}
