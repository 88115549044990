import { NATIVE_MINT } from '@solana/spl-token'
import { GiftCreateTypeEnum } from 'api/gift/type'
// import { mockBtcEvmChainId } from 'config/btc'
// import { mockTonChainId } from 'config/ton'
import { mockSolEvmChainId } from 'config/sol'
import { AssetsToken } from '@/stores/tokenStore/type/AssetsToken'
import { zeroAddress } from 'viem'
import configChains from '@/proviers/web3Provider/chains'
import { getChainByChainIdV2 } from '@/stores/walletStore/utils'
import { IWeb3ChainType } from '@/proviers/web3Provider/type'
export const CreateGiftAvgGasLimit = 500000n // show avg gas limit when estimate gas failed
export const MAX_CREATE_QUANTITY = 2000 // create gift max quantity
const initContract = () => {
  const env = import.meta.env.VITE_NODE_ENV
  if (env === 'production') {
    return {
      [configChains.ethereum.name]:
        '0x3fC4d2FfA0e6F9Ee5a494990B12455e7C01fB89b',
      [configChains.bsc.name]: '0x48602Cc75FB25d5ed745c8CcAB62B06E37fa71BB', // [bsc],
      [configChains.base.name]: '0x3fC4d2FfA0e6F9Ee5a494990B12455e7C01fB89b',
      [configChains.scroll.name]: '0x3fC4d2FfA0e6F9Ee5a494990B12455e7C01fB89b',
      [configChains.arbitrum.name]:
        '0x3fC4d2FfA0e6F9Ee5a494990B12455e7C01fB89b',
      [configChains.optimism.name]:
        '0x3fC4d2FfA0e6F9Ee5a494990B12455e7C01fB89b',
      [configChains.linea.name]: '0x3fC4d2FfA0e6F9Ee5a494990B12455e7C01fB89b',
      [configChains.duckChain.name]:
        '0x3fC4d2FfA0e6F9Ee5a494990B12455e7C01fB89b',
      [configChains.bitlayer.name]:
        '0x3fC4d2FfA0e6F9Ee5a494990B12455e7C01fB89b',
      [configChains.merlin.name]: '0x3fC4d2FfA0e6F9Ee5a494990B12455e7C01fB89b',
      [configChains.polygon.name]: '0xC26fcc30f44BCFFCDA65052c7349F255A22daB56',
      [configChains.b2network.name]:
        '0x3fC4d2FfA0e6F9Ee5a494990B12455e7C01fB89b',
      [configChains.b3.name]: '0x3fC4d2FfA0e6F9Ee5a494990B12455e7C01fB89b',
      [configChains.gravity.name]: '0x3fC4d2FfA0e6F9Ee5a494990B12455e7C01fB89b',
      [configChains.blast.name]: '0x3fC4d2FfA0e6F9Ee5a494990B12455e7C01fB89b',
      [configChains.botanixTestnet.name]:
        '0x3fC4d2FfA0e6F9Ee5a494990B12455e7C01fB89b',
      [configChains.berachain_bArtioTestnet.name]:
        '0xC0a0C29A0932b4d35D1ea3392EDC649D83D38d40',
      [configChains.ailayer.name]: '0x3fC4d2FfA0e6F9Ee5a494990B12455e7C01fB89b',
      [configChains.corn.name]: '0x3fC4d2FfA0e6F9Ee5a494990B12455e7C01fB89b', // mainnet
      [configChains.solana.name]:
        'G1P7zEDx3hDkxqnRBm8ce3eb45WAxrEsym7kDtiiV66g', // mainnet
      [configChains.ton.name]:
        'EQAcELIJH0wQ-gpJCTH1I89TimDsUspEP67TsK4CmBONI1Er',
      [configChains.sui.name]:
        '0x36202d50a85eae42dddc46bb43dc8a61c6a91fddf0981882a340b46e381fbedb'
    } as const
  }
  if (env === 'test') {
    return {
      [configChains.bsc.name]: '0x1864eDCaFb871f22A5204b6544F5a54be7Ff67fE', // [bsc],
      [configChains.base.name]: '0xC0a0C29A0932b4d35D1ea3392EDC649D83D38d40',
      [configChains.scroll.name]: '0xC26fcc30f44BCFFCDA65052c7349F255A22daB56',
      [configChains.solana.name]:
        '9FadSGnbtBjmjbkQVtfDztT2XTX5bHrhfFnLzCSheC9m', // mainnet
      [configChains.ton.name]:
        'EQAsi_7YtTVZEw0zTEj9NsfuWUexvmVj1SGKQU8T0W7qvns1',
      [configChains.sui.name]:
        '0xf48cf9a6322db554f98a056e0a1737cb178bdc1770d4d629dd7382fd3747a8f5'
    } as const
  }
  return {
    // # Test Contact
    // evm: '0xa1AAA2f80993c60f0aF2C5EeE137Bbd80eB27E6e',  // sepolia
    // sol: '8aEDKCb3DDEkp18NT83RyJWSsaiAY7y2nriJNYE9DhPL' / devnet v1
    // sol: 'EcYwBsusiDMGnoCmcPJNbadgY5jTrYTNxPi6Aq2GzAM2' // devnet v2
    [configChains.bsc.name]: '0x4e6a23639D532C5408547461081D271FBe519077', // [bsc],
    [configChains.base.name]: '0xC0a0C29A0932b4d35D1ea3392EDC649D83D38d40',
    [configChains.scroll.name]: '0xC26fcc30f44BCFFCDA65052c7349F255A22daB56',
    [configChains.solana.name]: '3w3EXFJGdERi1vqNt6UFXvXXqCKVTvbEQtmFdNfRaQfF', // devnet
    [configChains.tonTestnet.name]:
      'EQDzSoXI8PN40AyKw4bZAB4hlVT7efTcpBPuMYhxGmb8dFbt', // test net
  } as const
}

export const GIFT_CONTRACTS = initContract()

export const GIFT_NATIVES = {
  evm: zeroAddress,
  sol: NATIVE_MINT.toBase58()
}

export const checkGiftSupported = ({
  chains,
  chainId
}: {
  chains: IWeb3ChainType[] | undefined
  chainId?: number
}): boolean => {
  return !!chains?.find((chain) => chain?.id === chainId || -1)
}

export const checkTokenIsNative = (chainId: number, token: string) => {
  if (chainId === mockSolEvmChainId && token === GIFT_NATIVES.sol) {
    return true
  }
  return token === GIFT_NATIVES.evm
}

export const GiftTypeNames = {
  [GiftCreateTypeEnum.Identical]: 'Equal',
  [GiftCreateTypeEnum.Lottery]: 'Random'
}

export const getTokenAddress = ({
  token,
  chains
}: {
  token: AssetsToken
  chains: IWeb3ChainType[] | undefined
}): string => {
  if (!token.isNative) return token.address as string

  if (token.chainId === mockSolEvmChainId) {
    return GIFT_CONTRACTS.sol
  }
  const chain = getChainByChainIdV2({ chainId: token.chainId, chains })

  return GIFT_CONTRACTS[chain?.name || configChains.bsc.name]
}
