import React, { PropsWithChildren, useEffect, useMemo } from 'react'
import { WagmiProvider } from 'wagmi'
import { keepPreviousData, useQuery } from '@tanstack/react-query'
import { getTomoChains } from '@/api'
import {
  evmChainsConfigV2,
  getViemChainByTomoChain
} from '../../stores/chainsStore/utils'
import { IChainType, IWeb3ChainType, Web3Type } from './type'
import { observer } from 'mobx-react-lite'
import useChainsStore from '@/stores/chainsStore/hooks/useChainsStore'
import btc from './chains/wagmiConfig/btc'
import solana from './chains/wagmiConfig/solana'
import ton from './chains/wagmiConfig/ton'
import tron from './chains/wagmiConfig/tron'
import sui from './chains/wagmiConfig/sui'
import tonTestnet from './chains/wagmiConfig/tonTestnet'
import allChain from './chains/wagmiConfig/allChain'
import duckChainTest from './chains/wagmiConfig/duckChainTest'
import duckChain from './chains/wagmiConfig/duckChain'
import doge from './chains/wagmiConfig/doge'
import chains from './chains'
import { configChainsInfoAtom } from '@/state'
import { useAtom } from 'jotai'
import { convertChainsArrayToObject } from '@/hooks/api/useChainConfig'

const Provider = ({ children }: PropsWithChildren) => {
  const [configChainsInfo, setConfigChainsInfo] = useAtom(configChainsInfoAtom)

  const {
    evmChains,
    evmConfig,
    setEvmChains,
    setEvmConfig,
    setAllChains,
    setRenderChains,
    setMarketSelectChains,
    setSupportSwapChains,
    setSupportGiftChains,
    setUnSupportHistoryChains,
    setChains
  } = useChainsStore()

  useQuery({
    queryKey: ['chains'],
    placeholderData: keepPreviousData,
    refetchInterval: 300_000,
    queryFn: async () => {
      try {
        const res = await getTomoChains()

        const configChainsInfo = convertChainsArrayToObject(res)
        setConfigChainsInfo({ chain_id_name: configChainsInfo })
        const viemChains = getViemChainByTomoChain({ tomoChains: res })

        const evmChainsApi = viemChains.map((viemChain) => {
          return {
            chain: viemChain,
            id: viemChain.id,
            type: Web3Type.EVM,
            icon: viemChain.tomoEvmChain.icon,
            name: viemChain.name,
            networkType: viemChain.tomoEvmChain.isTestnet ? 'test' : 'main'
          } as IWeb3ChainType
        })

        if (evmChainsApi.length) {
          if (JSON.stringify(evmChainsApi) !== JSON.stringify(evmChains)) {
            setEvmChains(evmChainsApi)
            const config = evmChainsConfigV2(evmChainsApi)
            setEvmConfig(config)
            const renderChains = [
              btc,
              solana,
              ton,
              tron,
              sui,
              doge,
              ...evmChainsApi,
              tonTestnet
            ]
            setRenderChains(renderChains)
            const allChains = [allChain, ...renderChains]

            console.log({
              key: 'evmChainsConfigV2',
              allChains
            })
            setAllChains(allChains)
            setChains(allChains)
            setMarketSelectChains([
              allChain,
              ...renderChains.filter(
                (chain) =>
                  chain.id !== duckChainTest.id && chain.id !== duckChain.id
              )
            ])
            const notSupportSwapChains = [
              ...allChains.filter(
                (chain) => chain?.chain?.tomoEvmChain?.supportSwap === false
              ),
              chains.tonTestnet,
              chains.mevmTestnet,
              chains.doge
            ]

            const supportSwapChains = [
              ...allChains.filter((chain) => {
                return (
                  chain &&
                  chain?.type !== 'ALL' &&
                  typeof chain?.chain?.id === 'number' &&
                  !notSupportSwapChains.find(
                    (notSupportChain) => chain?.id === notSupportChain.id
                  ) &&
                  (chain?.type === 'EVM' ||
                    chain.type === 'SOL' ||
                    chain.type === 'TRON' ||
                    chain.type === 'SUI' ||
                    chain.type === 'BTC' ||
                    chain.type === 'TON')
                )
              })
            ]

            setSupportSwapChains(supportSwapChains)

            const unSupportHistoryChains = [
              ...allChains.filter(
                (chain) => chain?.chain?.tomoEvmChain?.supportHistory === false
              ),
              chains.bscTestnet,
              chains.ton
            ]
            console.log({
              unSupportHistoryChains,
              allChains
            })
            setUnSupportHistoryChains(unSupportHistoryChains)

            const SupportGiftChains = [
              ...allChains.filter(
                (chain) => chain?.chain?.tomoEvmChain?.supportGift === true
              ),
              chains.solana,
              chains.botanixTestnet,
              chains.berachain_bArtioTestnet,
              chains.sui,
              chains.doge,
              chains.ton
            ]

            setSupportGiftChains(SupportGiftChains)

            // setOkxChains(renderChains)
            // setChains()
          }
        }
        // return viemChains
        return evmChainsApi
      } catch (error) {
        return null
      }
    }
  })
  // evmConfig
  // evmChains
  // setEvmConfig
  // setEvmChains

  useEffect(() => {
    console.log({
      evmChains,
      evmConfig
    })
  }, [evmChains, evmConfig])

  return (
    evmConfig && <WagmiProvider config={evmConfig}>{children}</WagmiProvider>
  )
}

export default observer(Provider)
