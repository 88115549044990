import { atom } from 'jotai'
import { riskModalTypes, riskTypes } from '@/hooks/risk/useRiskToken'

export const riskModalDataAtom = atom<IRiskModalData | null>(null)

export interface IRiskModalData {
  modalType: riskModalTypes
  token: string
  markType: riskTypes | null
  rules: Array<{ type: riskTypes, typeText: string, list: string[] }>
  onContinue?: () => void
  onCancel?: () => void
  onGotIt?: () => void
}
