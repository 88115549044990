import configChains from 'proviers/web3Provider/chains'
import { IChainId } from 'proviers/web3Provider/type'
import { mockTonOkxChainID } from 'config/ton'
import { ISwapTokenType } from '@/pages/swap/hooks/type'
import { AssetsToken } from '@/stores/tokenStore/type/AssetsToken'
import { TOMO_CONFIG_CHAINS_INFO } from '@/state/tomoKeys'
import { configChainsInfoAtom } from '@/state'
import { useAtom } from 'jotai'

export const getSymbolnConfigChainIdByChain = (chain: string) => {
  const stored = localStorage.getItem(TOMO_CONFIG_CHAINS_INFO)
  let configChainId

  if (stored) {
    try {
      const configs = JSON.parse(stored)
      const chainsObj = configs.chain_id_name
      configChainId = Object.keys(chainsObj).find(
        (key) => chainsObj[key] === chain
      )
    } catch (error) {
      console.warn('system chain not config')
    }
  }
  return configChainId
}

const useChains = () => {
  const [configChainsInfo, setConfigChainsInfo] = useAtom(configChainsInfoAtom)

  const getChainIdByName = (
    value: string | undefined
  ): IChainId | undefined => {
    if (configChainsInfo && value) {
      for (const [key, val] of Object.entries(configChainsInfo.chain_id_name)) {
        if (val === value) {
          return Number(
            key == mockTonOkxChainID.toString()
              ? configChains.ton.id.toString()
              : key
          ) as IChainId
        }
      }
    }
    return undefined
  }

  const getChainNameById = (chainId: IChainId | undefined) => {
    const apiChainId =
      chainId === configChains.ton.id ? mockTonOkxChainID : chainId
    if (configChainsInfo && typeof apiChainId === 'number') {
      return configChainsInfo.chain_id_name[apiChainId]
    }
    return undefined
  }

  const getITokenBySwapToken = (
    swapToken: ISwapTokenType | undefined
  ): AssetsToken | undefined => {
    if (!swapToken) return
    const assets: AssetsToken = {
      isNative: !swapToken?.address,
      isToken: !!swapToken?.address,
      chainId: getChainIdByName(swapToken?.chain) ?? -1,
      decimals: swapToken?.decimals ?? 18,
      symbol: swapToken?.symbol ?? '',
      name: swapToken?.name ?? '',
      address: swapToken?.address ?? '',
      balance: '0',
      price: Number(swapToken?.priceUsd),
      image: swapToken?.imageUrl ?? '',
      source: 'all',
      id: `${swapToken?.address ?? ''}-${
        getChainIdByName(swapToken?.chain) ?? -1
      }-${swapToken?.symbol ?? ''}`,
      formatted: '0',
      whiteToken: undefined,
      customToken: {
        ID: 0,
        chain: swapToken?.chain,
        display_name: swapToken.displayName,
        chain_id: getChainIdByName(swapToken?.chain) ?? -1,
        created_at: 0,
        decimals: swapToken?.decimals ?? 18,
        image: swapToken?.imageUrl ?? '',
        market_cap: swapToken?.marketCap ?? 0,
        name: swapToken?.name ?? '',
        price: Number(swapToken?.priceUsd),
        price_change_h24: swapToken?.priceChangeH24 ?? 0,
        symbol: swapToken?.symbol ?? '',
        token: swapToken?.symbol ?? '',
        uid: 0
      }
    }
    return assets
  }

  return {
    getChainIdByName,
    getChainNameById,
    getITokenBySwapToken
  }
}

export default useChains
