import logoSvg from '@/assets/imgs/logo.svg'
import tgSvg from '@/assets/imgs/tg.svg'
import ethSvg from '@/assets/imgs/chains/eth.svg'
import duckChainSvg from '@/assets/imgs/chains/duck-chain.svg'
import btcSvg from '@/assets/imgs/chains/btc.svg'
import solSvg from '@/assets/imgs/chains/sol.svg'
import bscSvg from '@/assets/imgs/chains/bsc.svg'
import arbSvg from '@/assets/imgs/chains/arb.svg'
import opSvg from '@/assets/imgs/chains/op.svg'
import baseSvg from '@/assets/imgs/chains/base.svg'
import lineaSvg from '@/assets/imgs/chains/linea.svg'
import blastSvg from '@/assets/imgs/chains/blast.svg'
import polygonSvg from '@/assets/imgs/chains/polygon.svg'
import avaxSvg from '@/assets/imgs/chains/avax.svg'
import mantleSvg from '@/assets/imgs/chains/mantle.svg'
import scrollSvg from '@/assets/imgs/chains/scroll.svg'
import b3Jpg from '@/assets/imgs/chains/b3.jpg'
import b3Svg from '@/assets/imgs/chains/b3.svg'
import suiSvg from '@/assets/imgs/chains/sui.svg'
import allChainSvg from '@/assets/imgs/chains/all-chain.svg'
import UpSvg from '@/assets/imgs/up.svg'
import DownSvg from '@/assets/imgs/down.svg'
import copySvg from '@/assets/imgs/copy.svg'
import copy2Svg from '@/assets/imgs/copy2.svg'
import shareSvg from '@/assets/imgs/share.svg'
import roundUpSvg from '@/assets/imgs/round-up.svg'
import roundDownSvg from '@/assets/imgs/round-down.svg'
import usdtSvg from '@/assets/imgs/tokens/usdt.svg'
import bnbSvg from '@/assets/imgs/tokens/bnb.svg'
import tgBlueSvg from '@/assets/imgs/socialMedia/tg-blue.svg'
import tgWhiteSvg from '@/assets/imgs/socialMedia/tg-white.svg'
import orbiterSvg from '@/assets/imgs/socialMedia/orbiter.svg'
import xSvg from '@/assets/imgs/socialMedia/x.svg'
import tiktokSvg from '@/assets/imgs/socialMedia/tiktok.svg'
import instagramSvg from '@/assets/imgs/socialMedia/instagram.svg'
import jasperJPG from '@/assets/imgs/socialMedia/jasper.jpg'
import kiloexJPG from '@/assets/imgs/socialMedia/kiloex.jpg'
import galxeJPG from '@/assets/imgs/socialMedia/galxe.jpeg'
import duckchainJPG from '@/assets/imgs/socialMedia/duckchain.png'
import timonPng from '@/assets/imgs/socialMedia/timon.png'
import xpinPng from '@/assets/imgs/socialMedia/xpin.png'
import scratchwinJPG from '@/assets/imgs/socialMedia/scratchwin.jpg'
import rangoSvg from '@/assets/imgs/socialMedia/rango.svg'
import sunSwapSvg from '@/assets/imgs/socialMedia/sun-swap.svg'
import jupiterSvg from '@/assets/imgs/socialMedia/jupiter.svg'
import stonfiSvg from '@/assets/imgs/socialMedia/ston-fi.svg'
import mintSvg from '@/assets/imgs/mint.svg'
import editSvg from '@/assets/imgs/edit.svg'
import loginBg1Svg from '@/assets/imgs/login-bg-1.svg'
import loginBg2Svg from '@/assets/imgs/login-bg-2.svg'
import receiveSvg from '@/assets/imgs/receive.svg'
import sendSvg from '@/assets/imgs/send.svg'
import historySvg from '@/assets/imgs/history.svg'
import recoveryPhraseSvg from '@/assets/imgs/recovery-phrase.svg'
import toastSuccessSvg from '@/assets/imgs/toast/success.svg'
import toastErrorSvg from '@/assets/imgs/toast/error.svg'
import toastInfoSvg from '@/assets/imgs/toast/info.svg'
import toastWarningSvg from '@/assets/imgs/toast/warning.svg'
import toastLoadingSvg from '@/assets/imgs/toast/loading.svg'
import rightSvg from '@/assets/imgs/right.svg'
import accountNameSvg from '@/assets/imgs/account-name.svg'
import accountAddressSvg from '@/assets/imgs/account-address.svg'
import accountPhraseSvg from '@/assets/imgs/account-phrase.svg'
import accountKeySvg from '@/assets/imgs/account-key.svg'
import accountRemoveSvg from '@/assets/imgs/account-remove.svg'
import accountRemoveEnableSvg from '@/assets/imgs/account-remove-enable.svg'
import qrCodeSvg from '@/assets/imgs/qr-code.svg'
import qrCode2Svg from '@/assets/imgs/qr-code2.svg'
import accountEditSvg from '@/assets/imgs/account-edit.svg'
import noticeSvg from '@/assets/imgs/notice.svg'
import tgWhiteRoundedSvg from '@/assets/imgs/tg-white-rounded.svg'
import swapSvg from '@/assets/imgs/swap.svg'
import selectEmptySvg from '@/assets/imgs/select-empty.svg'
import selectedSvg from '@/assets/imgs/selected.svg'
import infoSvg from '@/assets/imgs/info.svg'
import infoGraySvg from '@/assets/imgs/info-gray.svg'
import rankSvg from '@/assets/imgs/rank.svg'
import copyThemeSvg from '@/assets/imgs/copy-theme.svg'
import linkThemeSvg from '@/assets/imgs/link-theme.svg'
import linkSvg from '@/assets/imgs/link.svg'
import avatarPng from '@/assets/imgs/avatar.png'
import tgWhiteLineSvg from '@/assets/imgs/tg-white.svg'
import CloseRoundSvg from '@/assets/imgs/close-round.svg'
import roundSvg from '@/assets/imgs/round.svg'
import roundCheckedSvg from '@/assets/imgs/round-checked.svg'
import emptySvg from '@/assets/imgs/empty.svg'
import okxPng from '@/assets/imgs/okx.png'

import depositHomeSvg from '@/assets/imgs/deposit-home.svg'
import receiveHomeSvg from '@/assets/imgs/receive-home.svg'
import swapHomeSvg from '@/assets/imgs/swap-home.svg'
import tokenSwapSvg from '@/assets/imgs/token-swap.svg'

import arbJpeg from '@/assets/imgs/chains/arb.jpeg'
import cosmosPng from '@/assets/imgs/chains/cosmos.png'
import stakeSvg from '@/assets/imgs/stake.svg'
import babylonSvg from '@/assets/imgs/icon-babylon.svg'
import lombardSvg from '@/assets/imgs/icon-lombard.svg'
import lorenzoSvg from '@/assets/imgs/icon-lorenzo.svg'
import backSvg from '@/assets/imgs/icon-back.svg'
import btcDownSvg from '@/assets/imgs/btc-down.svg'
import simpleCopySvg from '@/assets/imgs/icon-copy.svg'
import checkPassSvg from '@/assets/imgs/check-pass.svg'
import tomoBlackSvg from '@/assets/imgs/icon-tomo-blank.svg'
import buySvg from '@/assets/imgs/buy.svg'
import roundedPlusSvg from '@/assets/imgs/rounded-plus.svg'
import arrowRightSvg from '@/assets/imgs/arrow-right.svg'
import arrowRightRoundSvg from '@/assets/imgs/arrow-right-round.svg'
import arrowDownSvg from '@/assets/imgs/arrow-down.svg'
import tonSvg from '@/assets/imgs/chains/ton.png'
import tonSvg2 from '@/assets/imgs/chains/ton.svg'
import rewardTokensSvg from '@/assets/imgs/task/reward-tokens.svg'
import share2Svg from '@/assets/imgs/share2.svg'
import share2ThemeSvg from '@/assets/imgs/share2-theme.svg'
import tokenSwapOutlinedSvg from '@/assets/imgs/task/token-swap-outlined.svg'
import RightBlackSvg from '@/assets/imgs/right-black.svg'
import swapLoadingSvg from '@/assets/imgs/swap-loading.svg'
import swapSuccessSvg from '@/assets/imgs/swap-success.svg'
import swapLineSvg from '@/assets/imgs/swap-line.svg'
import swapRightSvg from '@/assets/imgs/swap-right.svg'
import swapHistoryLoadingSvg from '@/assets/imgs/swap-history-loading.svg'
import swapHistoryItemSvg from '@/assets/imgs/swap-history-item.svg'
import swapWhiteSvg from '@/assets/imgs/swap-white.svg'
import popupCloseSvg from '@/assets/imgs/popup-close.svg'
import checkboxSvg from '@/assets/imgs/checkbox.svg'
import checkboxActiveSvg from '@/assets/imgs/checkbox-active.svg'
import visaSvg from '@/assets/imgs/visa.svg'
import mastercardSvg from '@/assets/imgs/mastercard.svg'
import backupSvg from '@/assets/imgs/backup.svg'
import resetSvg from '@/assets/imgs/reset.svg'
import secretSvg from '@/assets/imgs/secret.svg'
import tipsSvg from '@/assets/imgs/tips.svg'
import exchangeSvg from '@/assets/imgs/exchange.svg'
import telegramSvg from '@/assets/imgs/telegram.png'
import telegram2Svg from '@/assets/imgs/telegram2.svg'
import weiboPng from '@/assets/imgs/weibo.png'
import whyPng from '@/assets/imgs/tokens/why.png'
import wifPng from '@/assets/imgs/tokens/wif.png'
import copy2ThemeSvg from '@/assets/imgs/copy2-theme.svg'
import mailSvg from '@/assets/imgs/mail.svg'
import backupMailSvg from '@/assets/imgs/backup_mail.svg'
import qrcodeTwoSvg from '@/assets/imgs/qrcode_two.svg'
import copyBlackSvg from '@/assets/imgs/copy_black.svg'
import copyGraySvg from '@/assets/imgs/copy_gray.svg'
import timeSvg from '@/assets/imgs/time.svg'
import iconTime from '@/assets/imgs/icon-time.svg'
import gasSvg from '@/assets/imgs/gas.svg'
import confirmArrowSvg from '@/assets/imgs/confirm-arrow.svg'
import roundCheckedWhiteSvg from '@/assets/imgs/round-checked-white.svg'
import marketInfoSvg from '@/assets/imgs/market/info.svg'
import marketCheckedSvg from '@/assets/imgs/market/checked.svg'
import marketLogoInvitePng from '@/assets/imgs/market/logoInvite.png'
import downloadSvg from '@/assets/imgs/download.svg'
import securitySvg from '@/assets/imgs/security.svg'
import successRoundSvg from '@/assets/imgs/success-round.svg'
import accountAddress2Svg from '@/assets/imgs/account-address2.svg'
import keySvg from '@/assets/imgs/key.svg'
import sliveryXSvg from '@/assets/imgs/slivery-x.svg'
import checkedThemeSvg from '@/assets/imgs/checked-theme.svg'
import securityKeySvg from '@/assets/imgs/security-key.svg'
import back1Svg from '@/assets/imgs/back-1.svg'
import spinerSvg from '@/assets/imgs/spiner.svg'
import eyeSvg from '@/assets/imgs/eye.svg'
import eyeCloseSvg from '@/assets/imgs/eye-close.svg'
import giftSvg from '@/assets/imgs/gift.svg'
import referSvg from '@/assets/imgs/refer.svg'
import lombardLogoSvg from '@/assets/imgs/task/lombard.svg'
import stakestoneLogoSvg from '@/assets/imgs/task/stakestone.svg'
import babylonLogoSvg from '@/assets/imgs/task/babylon.svg'
import qrCode3Svg from '@/assets/imgs/qr-code3.svg'
import ellipsisRoundSvg from '@/assets/imgs/ellipsis-round.svg'
import settingSvg from '@/assets/imgs/setting.svg'
import externalWalletSvg from '@/assets/imgs/external-wallet.svg'
import scanSvg from '@/assets/imgs/scan.svg'
import sentSubmitted from '@/assets/imgs/send-submitted.png'
import bnbMaticSvg from '@/assets/imgs/bnb-matic.svg'
import payPinSvg from '@/assets/imgs/pay_pin.svg'
import spiner2Svg from '@/assets/imgs/spiner2.svg'
import spiner3Svg from '@/assets/imgs/spiner3.svg'
import tronSvg from '/assets/imgs/chains/tron.svg'
const errorBoundarySvg = '/assets/imgs/error-boundary.svg'
const logoOutlinedSvg = '/assets/imgs/task/logo-outlined.svg'
const loginTomoLogoSvg = '/assets/imgs/login-tomo-logo.svg'
const referLogoSvg = '/assets/imgs/refer-logo.svg'
import tradeSvg from '@/assets/imgs/task/trade.svg'
import listSvg from '@/assets/imgs/list.svg'
import usdSvg from '@/assets/imgs/ramp/USD.svg'
import alchemyPay from '@/assets/imgs/ramp/alchemy.png'
import cryptoSentSvg from '@/assets/imgs/ramp/crypto-sent.svg'
import divide from '@/assets/imgs/ramp/divide.svg'
import result from '@/assets/imgs/ramp/result.svg'
import confirmOrder from '@/assets/imgs/ramp/confirm-order.svg'
import inTransfer from '@/assets/imgs/ramp/in-transfer.svg'
import success from '@/assets/imgs/ramp/success.svg'
import loading from '@/assets/imgs/ramp/loading.svg'
import processIcon from '@/assets/imgs/ramp/processing-icon.svg'
import completedIcon from '@/assets/imgs/ramp/completed-icon.svg'
import failedIcon from '@/assets/imgs/ramp/failed-icon.svg'
import closedIcon from '@/assets/imgs/ramp/closed-icon.svg'
import evmSvg from '@/assets/imgs/evm.svg'
import Right2Svg from '@/assets/imgs/right2.svg'
import MnemonicLogoSvg from '@/assets/imgs/mnemonic/logo.svg'
import MnemonicLogo1Svg from '@/assets/imgs/mnemonic/logo_1.svg'
import MnemonicLogoDark1Svg from '@/assets/imgs/mnemonic/logo_dark_1.svg'
import MnemonicLogo2Svg from '@/assets/imgs/mnemonic/logo_2.svg'
import MnemonicLogoDark2Svg from '@/assets/imgs/mnemonic/logo_dark_2.svg'
import MnemonicLogo3Svg from '@/assets/imgs/mnemonic/logo_3.svg'
import MnemonicLogoDark3Svg from '@/assets/imgs/mnemonic/logo_dark_3.svg'
import MnemonicLogo4Svg from '@/assets/imgs/mnemonic/logo_4.svg'
import MnemonicLogoDark4Svg from '@/assets/imgs/mnemonic/logo_dark_4.svg'
import MnemonicLogo5Svg from '@/assets/imgs/mnemonic/logo_5.svg'
import MnemonicLogoDark5Svg from '@/assets/imgs/mnemonic/logo_dark_5.svg'
import MnemonicKeySvg from '@/assets/imgs/mnemonic/key.svg'
import MnemonicClockSvg from '@/assets/imgs/mnemonic/clock.svg'
import MnemonicNoCheckSvg from '@/assets/imgs/mnemonic/nocheck.svg'
import MnemonicNoCheckDarkSvg from '@/assets/imgs/mnemonic/nocheck_dark.svg'
import MnemonicCheckedSvg from '@/assets/imgs/mnemonic/checked.svg'
import MnemonicCheckedDarkSvg from '@/assets/imgs/mnemonic/checked_dark.svg'
import MnemonicTickSvg from '@/assets/imgs/mnemonic/tick.svg'
import MnemonicForkSvg from '@/assets/imgs/mnemonic/fork.svg'
import MnemonicEyehideSvg from '@/assets/imgs/mnemonic/eyehide.svg'
import MnemonicEyehideDarkSvg from '@/assets/imgs/mnemonic/eyehide_dark.svg'
import MnemonicWarningSvg from '@/assets/imgs/mnemonic/warning.svg'
import GAuthLogo from '@/assets/imgs/gauth/ga_logo.svg'
import GAuthSuccess from '@/assets/imgs/gauth/ga_success.svg'
import GAuthFail from '@/assets/imgs/gauth/ga_fail.svg'
import taskReward from '@/assets/imgs/task-reward.svg'
import taskPointDetailSvg from '@/assets/imgs/task-point-detail.svg'
import taskPointDetailDarkSvg from '@/assets/imgs/task-point-detail-dark.svg'
import friendsInvitedRewardSvg from '@/assets/imgs/friends-invited-reward.svg'
import rampHistory from '@/assets/imgs/ramp/history-icon.svg'
import rampEmpty from '@/assets/imgs/ramp/empty.svg'
import failedStatusIcon from '@/assets/imgs/ramp/failed-status-icon.svg'
import applePay from '@/assets/imgs/ramp/apple-pay-icon.svg'
import applyPayDark from '@/assets/imgs/ramp/applePay-dark.svg'
import googlePay from '@/assets/imgs/ramp/google-pay-icon.svg'
import googlePayDark from '@/assets/imgs/ramp/google-pay-dark.svg'
import googlePaylight from '@/assets/imgs/ramp/google-pay-light.svg'
import noSupportImg from '@/assets/imgs/ramp/no-support-img.svg'
import receiveToRampDark from '@/assets/imgs/receive/receive-to-ramp-dark.png'
import receiveToRampLight from '@/assets/imgs/receive/receive-to-ramp-light.png'
import claimedGiftDown from '@/assets/imgs/gift/claimed-gift-down.png'
import sendGiftUp from '@/assets/imgs/gift/send-gift-up.png'
import rampLoading from '@/assets/imgs/ramp/loading-icon2.svg'
import applePayDark from '@/assets/imgs/ramp/applePay-dark.svg'
import giftDetail from '@/assets/imgs/gift/gift-detail.png'
import giftBox from '@/assets/imgs/gift/gift-box.png'
import giftBoxOpen from '@/assets/imgs/gift/git-box-open.png'
import ailayerPng from '@/assets/imgs/chains/ailayer.png'
import ailayerSvg from '@/assets/imgs/chains/ailayer.svg'
import bitlayerPng from '@/assets/imgs/chains/bitlayer.png'
import bitlayerSvg from '@/assets/imgs/chains/bitlayer.svg'
import merlinPng from '@/assets/imgs/chains/merlin.png'
import botanixTestnetJpg from '@/assets/imgs/chains/botanix-testnet.jpg'
import berachainTestSvg from '@/assets/imgs/chains/berachain.svg'
import storyTestSvg from '@/assets/imgs/chains/story.svg'
import storyTestJpg from '@/assets/imgs/chains/story.jpg'
import berachainTestJpg from '@/assets/imgs/chains/berachain.jpg'
import worldChainPng from '@/assets/imgs/chains/world-chain.png'
import worldChainSvg from '@/assets/imgs/chains/world-chain.svg'
import worldChainJpg from '@/assets/imgs/chains/world-chain.jpg'
import b2Png from '@/assets/imgs/chains/b2.png'
import b2Svg from '@/assets/imgs/chains/b2.svg'
import seiSvg from '@/assets/imgs/chains/sei.svg'
import lorenzoChainSvg from '@/assets/imgs/chains/lorenzo.svg'
import gravityJpg from '@/assets/imgs/chains/gravity.jpg'
import zksyncSvg from '@/assets/imgs/chains/zksync.svg'
import zksyncPng from '@/assets/imgs/chains/zksync.png'
import opbnbSvg from '@/assets/imgs/chains/opbnb.svg'
import tacSvg from '@/assets/imgs/chains/tac.svg'
import mevmTestnetSvg from '@/assets/imgs/chains/mevm-testnet.svg'
import promTestnetSvg from '@/assets/imgs/chains/prom-testnet.svg'
import neoXJpg from '@/assets/imgs/chains/neo.jpg'
import bobPng from '@/assets/imgs/chains/bob.png'
import corn from '@/assets/imgs/chains/corn-logo.svg'
import JoinVsg from '@/assets/imgs/market/join.svg'
import CommunitySvg from '@/assets/imgs/market/community.svg'
import WhatIsStarLight from '@/assets/imgs/starForGas/what-is-star-light.svg'
import WhatIsStarDark from '@/assets/imgs/starForGas/what-is-star-dark.svg'
import TgStar from '@/assets/imgs/starForGas/tg-star.svg'
import NewUserGift from '@/assets/imgs/gift/new-user-gift.svg'
import Doge from '@/assets/imgs/chains/doge.svg'
import riskPolygonSvg from '@/assets/imgs/risk/polygon.svg'
import riskPolygonDarkSvg from '@/assets/imgs/risk/polygon-dark.svg'
import riskMaskIcon from '@/assets/imgs/risk/risk-icon.svg'
import riskMaskDarkIcon from '@/assets/imgs/risk/rsk-icon-dark.svg'
import riskPoweredByIcon from '@/assets/imgs/risk/riskPowerByDark.svg'

const chainlistSrc = {
  btc: '/assets/imgs/chainlist/btc.svg',
  eth: '/assets/imgs/chainlist/eth.svg',
  arb: '/assets/imgs/chainlist/arb.svg',
  ava: '/assets/imgs/chainlist/avalanche.svg',
  b3: '/assets/imgs/chainlist/b3.svg',
  base: '/assets/imgs/chainlist/base.svg',
  blast: '/assets/imgs/chainlist/blast.svg',
  bsc: '/assets/imgs/chainlist/bsc.svg',
  duck: '/assets/imgs/chainlist/duckchain.svg',
  linea: '/assets/imgs/chainlist/linea.svg',
  mantle: '/assets/imgs/chainlist/mantle.svg',
  polygon: '/assets/imgs/chainlist/polygon.svg',
  scroll: '/assets/imgs/chainlist/scroll.svg',
  sol: '/assets/imgs/chainlist/sol.svg',
  ton: '/assets/imgs/chainlist/ton.svg',
  tron: '/assets/imgs/chainlist/tron.svg',
  storyTestnet: '/assets/imgs/chains/story.svg',
  berachainTestnet: '/assets/imgs/chains/berachain.jpg',
  botanixTestnet: '/assets/imgs/chains/botanix-testnet.jpg',
  ailayer: '/assets/imgs/chains/ailayer.png',
  bitlayer: '/assets/imgs/chains/bitlayer.png',
  merlin: '/assets/imgs/chains/merlin.png',
  doge: '/assets/imgs/chains/doge.svg'
}

const chainsSvgs = {
  duckChainSvg: chainlistSrc.duck,
  ethSvg: chainlistSrc.eth,
  allChainSvg,
  btcSvg: chainlistSrc.btc,
  // solSvg: chainlistSrc.sol,
  solSvg: solSvg,
  arbSvg: chainlistSrc.arb,
  arbJpeg,
  cosmosPng,
  opSvg,
  bscSvg: chainlistSrc.bsc,
  lineaSvg: chainlistSrc.linea,
  blastSvg: chainlistSrc.blast,
  polygonSvg: chainlistSrc.polygon,
  avaxSvg: chainlistSrc.ava,
  mantleSvg: chainlistSrc.mantle,
  baseSvg: chainlistSrc.base,
  scrollSvg: chainlistSrc.scroll,
  storyTestnetSvg: storyTestJpg,
  berachainTestnetSvg: berachainTestSvg,
  berachainTestnetJpg: berachainTestJpg,
  botanixTestnetJpg: botanixTestnetJpg,
  worldChainPng: worldChainJpg,
  b3Jpg,
  b3Svg: chainlistSrc.b3,
  tronChainSvg: chainlistSrc.tron,
  tronSvg,
  suiSvg,
  opbnbSvg,
  tonSvg: chainlistSrc.ton,
  tonSvg2,
  tacSvg,
  corn,
  ailayer: ailayerSvg,
  // ailayer: ailayerPng,
  bitlayer: bitlayerSvg,
  merlin: merlinPng,
  mevmTestnet: mevmTestnetSvg,
  promTestnet: promTestnetSvg,
  neoX: neoXJpg,
  bob: bobPng,
  b2Png,
  b2Svg,
  seiSvg,
  gravityJpg,
  lorenzoChainSvg,
  zksyncPng,
  zksyncSvg,
  doge: Doge
}
const tokensSvgs = {
  usdtSvg,
  whyPng,
  wifPng,
  bnbSvg
}
const socialMediaSvgs = {
  tgBlueSvg,
  tgWhiteSvg,
  orbiterSvg,
  rangoSvg,
  jupiterSvg,
  stonfiSvg,
  okxPng,
  xSvg,
  tiktokSvg,
  instagramSvg,
  jasperJPG,
  kiloexJPG,
  galxeJPG,
  duckchainJPG,
  timonPng,
  xpinPng,
  scratchwinJPG,
  sunSwapSvg
}
const toastSvgs = {
  toastSuccessSvg,
  toastErrorSvg,
  toastInfoSvg,
  toastWarningSvg,
  toastLoadingSvg
}
const arrowSvgs = {
  rightNewSvg: arrowRightSvg,
  rightSvg,
  downSvg: DownSvg,
  leftSvg: popupCloseSvg,
  rightV2Svg: RightBlackSvg,
  Right2Svg: Right2Svg
}
const mnemonicImgs = {
  logoSvg: MnemonicLogoSvg,
  logo1Svg: MnemonicLogo1Svg,
  logoDark1Svg: MnemonicLogoDark1Svg,
  logo2Svg: MnemonicLogo2Svg,
  logoDark2Svg: MnemonicLogoDark2Svg,
  logo3Svg: MnemonicLogo3Svg,
  logoDark3Svg: MnemonicLogoDark3Svg,
  logo4Svg: MnemonicLogo4Svg,
  logoDark4Svg: MnemonicLogoDark4Svg,
  logo5Svg: MnemonicLogo5Svg,
  logoDark5Svg: MnemonicLogoDark5Svg,
  keySvg: MnemonicKeySvg,
  clockSvg: MnemonicClockSvg,
  nocheckSvg: MnemonicNoCheckSvg,
  nocheckDarkSvg: MnemonicNoCheckDarkSvg,
  checkedSvg: MnemonicCheckedSvg,
  checkedDarkSvg: MnemonicCheckedDarkSvg,
  tickSvg: MnemonicTickSvg,
  forkSvg: MnemonicForkSvg,
  eyehideSvg: MnemonicEyehideSvg,
  eyehideDarkSvg: MnemonicEyehideDarkSvg,
  warning: MnemonicWarningSvg
}
const gauthImgs = {
  gaLogo: GAuthLogo,
  gaSuccess: GAuthSuccess,
  gaFail: GAuthFail
}

const starForGasSvg = {
  WhatIsStarLight,
  WhatIsStarDark,
  TgStar
}

const riskSvg = {
  polygon: riskPolygonSvg,
  polygoDark: riskPolygonDarkSvg,
  riskMaskIcon,
  riskMaskDarkIcon,
  riskPoweredByIcon
}
export {
  taskPointDetailDarkSvg,
  friendsInvitedRewardSvg,
  taskPointDetailSvg,
  taskReward,
  gasSvg,
  timeSvg,
  iconTime,
  popupCloseSvg,
  swapHistoryItemSvg,
  swapWhiteSvg,
  swapHistoryLoadingSvg,
  swapRightSvg,
  swapLineSvg,
  swapLoadingSvg,
  swapSuccessSvg,
  swapHomeSvg,
  depositHomeSvg,
  receiveHomeSvg,
  stakeSvg,
  babylonSvg,
  lombardSvg,
  lorenzoSvg,
  backSvg,
  btcDownSvg,
  simpleCopySvg,
  checkPassSvg,
  tomoBlackSvg,
  loginTomoLogoSvg,
  swapSvg,
  selectEmptySvg,
  selectedSvg,
  accountRemoveSvg,
  qrCodeSvg,
  qrCode2Svg,
  accountRemoveEnableSvg,
  accountNameSvg,
  accountPhraseSvg,
  accountKeySvg,
  accountAddressSvg,
  arrowSvgs,
  mnemonicImgs,
  gauthImgs,
  arrowDownSvg,
  tgWhiteSvg,
  recoveryPhraseSvg,
  historySvg,
  loginBg2Svg,
  loginBg1Svg,
  socialMediaSvgs,
  logoSvg,
  tgSvg,
  chainsSvgs,
  tokensSvgs,
  UpSvg,
  DownSvg,
  copySvg,
  copy2Svg,
  shareSvg,
  roundUpSvg,
  roundDownSvg,
  mintSvg,
  editSvg,
  sendSvg,
  receiveSvg,
  toastSvgs,
  accountEditSvg,
  noticeSvg,
  tgWhiteRoundedSvg,
  infoSvg,
  infoGraySvg,
  rankSvg,
  copyThemeSvg,
  linkThemeSvg,
  linkSvg,
  avatarPng,
  tgWhiteLineSvg,
  CloseRoundSvg,
  roundSvg,
  roundCheckedSvg,
  emptySvg,
  tokenSwapSvg,
  okxPng,
  buySvg,
  referLogoSvg,
  roundedPlusSvg,
  backupSvg,
  resetSvg,
  secretSvg,
  tipsSvg,
  exchangeSvg,
  telegramSvg,
  telegram2Svg,
  weiboPng,
  rewardTokensSvg,
  share2Svg,
  share2ThemeSvg,
  logoOutlinedSvg,
  tokenSwapOutlinedSvg,
  RightBlackSvg,
  checkboxSvg,
  checkboxActiveSvg,
  visaSvg,
  mastercardSvg,
  copy2ThemeSvg,
  mailSvg,
  backupMailSvg,
  qrcodeTwoSvg,
  copyBlackSvg,
  copyGraySvg,
  arrowRightRoundSvg,
  confirmArrowSvg,
  roundCheckedWhiteSvg,
  marketInfoSvg,
  marketCheckedSvg,
  marketLogoInvitePng,
  downloadSvg,
  securitySvg,
  successRoundSvg,
  accountAddress2Svg,
  keySvg,
  sliveryXSvg,
  checkedThemeSvg,
  securityKeySvg,
  back1Svg,
  spinerSvg,
  eyeSvg,
  eyeCloseSvg,
  giftSvg,
  referSvg,
  errorBoundarySvg,
  lombardLogoSvg,
  stakestoneLogoSvg,
  qrCode3Svg,
  ellipsisRoundSvg,
  settingSvg,
  babylonLogoSvg,
  externalWalletSvg,
  scanSvg,
  sentSubmitted,
  bnbMaticSvg,
  payPinSvg,
  spiner2Svg,
  spiner3Svg,
  tradeSvg,
  listSvg,
  evmSvg,
  usdSvg,
  alchemyPay,
  cryptoSentSvg,
  divide,
  result,
  confirmOrder,
  inTransfer,
  success,
  loading,
  processIcon,
  completedIcon,
  failedIcon,
  closedIcon,
  rampHistory,
  rampEmpty,
  failedStatusIcon,
  applePay,
  applyPayDark,
  googlePay,
  googlePayDark,
  googlePaylight,
  noSupportImg,
  receiveToRampDark,
  receiveToRampLight,
  claimedGiftDown,
  sendGiftUp,
  rampLoading,
  applePayDark,
  giftDetail,
  giftBox,
  giftBoxOpen,
  JoinVsg,
  CommunitySvg,
  starForGasSvg,
  NewUserGift,
  riskSvg
}
