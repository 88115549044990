import arbitrum from './wagmiConfig/arbitrum'
import arbitrumSepolia from './wagmiConfig/arbitrumSepolia'
import avalanche from './wagmiConfig/avalanche'
import base from './wagmiConfig/base'
import baseSepolia from './wagmiConfig/baseSepolia'
import blast from './wagmiConfig/blast'
import blastSepolia from './wagmiConfig/blastSepolia'
import bsc from './wagmiConfig/bsc'
import bscTestnet from './wagmiConfig/bscTestnet'
import ethereum from './wagmiConfig/ethereum'
import linea from './wagmiConfig/linea'
import lineaSepolia from './wagmiConfig/lineaSepolia'
import mantle from './wagmiConfig/mantle'
import mantleSepoliaTestnet from './wagmiConfig/mantleSepoliaTestnet'
import optimism from './wagmiConfig/optimism'
import optimismSepolia from './wagmiConfig/optimismSepolia'
import polygon from './wagmiConfig/polygon'
import solana from './wagmiConfig/solana'
import allChain from './wagmiConfig/allChain'
import btc from './wagmiConfig/btc'
import ton from './wagmiConfig/ton'
import tron from './wagmiConfig/tron'
import scroll from './wagmiConfig/scroll'
import b3 from './wagmiConfig/b3'
import bitlayer from './wagmiConfig/bitlayer'
import merlin from './wagmiConfig/merlin'
import worldchain from './wagmiConfig/world'
import duckChainTest from './wagmiConfig/duckChainTest'
import duckChain from './wagmiConfig/duckChain'
import sui from './wagmiConfig/sui'
import ailayer from './wagmiConfig/ailayer'
import { mockTronOKXChainId } from '@/config/tron'
import storyNetworkTestnet from './wagmiConfig/storyNetworkTestnet'
import berachain_bArtioTestnet from './wagmiConfig/berachain_bArtioTestnet'
import botanixTestnet from './wagmiConfig/botanixTestnet'
import tonTestnet from './wagmiConfig/tonTestnet'
import cosmos from './wagmiConfig/cosmos'
import b2network from './wagmiConfig/b2network'
import zkSync from './wagmiConfig/zkSync'
import gravity from './wagmiConfig/gravity'
import sei from './wagmiConfig/sei'
import lorenzo from './wagmiConfig/lorenzo'
import opbnb from './wagmiConfig/opbnb'
import tacTestnet from './wagmiConfig/tacTestnet'
import mevmTestnet from './wagmiConfig/mevmTestnet'
import promTestnet from './wagmiConfig/promTestnet'
import neox from './wagmiConfig/neox'
import bob from './wagmiConfig/bob'
import corn from './wagmiConfig/corn'
import doge from './wagmiConfig/doge'
import suiTest from './wagmiConfig/suiTest'
import { IWeb3ChainType } from '../type'

export const prodEvmChains = [
  ethereum,
  bsc,
  arbitrum,
  optimism,
  linea,
  zkSync,
  polygon,
  base,
  blast,
  avalanche,
  bob,
  scroll,
  opbnb,
  b3,
  worldchain,
  duckChain,
  ailayer,
  bitlayer,
  merlin,
  corn,
  b2network,
  gravity,
  neox,
  sei,
  lorenzo,
  promTestnet,
  duckChainTest,
  storyNetworkTestnet,
  berachain_bArtioTestnet,
  botanixTestnet,
  // mevmTestnet,
  tacTestnet
]
// console.log(
//   'evm id',
//   prodEvmChains.map((item) => item.id)
// )

export const renderChains = [
  btc,
  solana,
  ton,
  tron,
  sui,
  cosmos,
  ...prodEvmChains,
  tonTestnet,
  doge
]

export const allChains = [allChain, ...renderChains]

export const chainIds = allChains
  .map((chain) => chain.id)
  .filter((item) => typeof item === 'number')

export const chainMappings = allChains.reduce(
  (prev, next) => {
    prev[next.name] = next
    return prev
  },
  {} as Record<string, IWeb3ChainType>
)

//?? b3,ailayer,duckChainTest,duckChain
export const okxChains = [
  ethereum,
  bsc,
  arbitrum,
  optimism,
  linea,
  polygon,
  base,
  blast,
  avalanche,
  scroll,
  zkSync,
  // opbnb,
  bitlayer,
  merlin,
  { ...tron, id: mockTronOKXChainId },
  sui,
  solana
]

const chains = {
  allChain,
  solana,
  arbitrum,
  arbitrumSepolia,
  avalanche,
  base,
  opbnb,
  baseSepolia,
  blast,
  blastSepolia,
  bsc,
  bscTestnet,
  ethereum,
  linea,
  lineaSepolia,
  mantle,
  mantleSepoliaTestnet,
  optimism,
  optimismSepolia,
  polygon,
  btc,
  corn,
  scroll,
  b3,
  tron,
  ton,
  duckChain,
  duckChainTest,
  ailayer,
  bitlayer,
  worldchain,
  merlin,
  sui,
  sei,
  lorenzo,
  storyNetworkTestnet,
  berachain_bArtioTestnet,
  botanixTestnet,
  tonTestnet,
  tacTestnet,
  zkSync,
  b2network,
  gravity,
  mevmTestnet,
  promTestnet,
  neox,
  bob,
  cosmos,
  doge,
  suiTest
}

export const marketChain = {
  [chains.btc.id]: {
    chain: 'BTC',
    token: 'bitcoin'
  },
  [chains.ethereum.id]: {
    chain: 'ETH',
    token: 'ethereum'
  },
  [chains.solana.id]: {
    chain: 'SOLANA',
    token: 'solana'
  },
  [chains.ton.id]: {
    chain: 'TON',
    token: 'ton'
  },
  [chains.tron.id]: {
    chain: 'TRON',
    token: 'tron'
  },
  [chains.bsc.id]: {
    chain: 'BSC',
    token: 'bnb'
  },
  [chains.arbitrum.id]: {
    chain: 'ARBITRUM',
    token: 'arb_eth'
  },
  [chains.base.id]: {
    chain: 'BASE',
    token: 'base_eth'
  },
  [chains.blast.id]: {
    chain: 'BLAST',
    token: 'blast'
  },
  [chains.avalanche.id]: {
    chain: 'AVAX',
    token: 'avax'
  },
  [chains.polygon.id]: {
    chain: 'POLYGON_POS',
    token: 'matic'
  },
  [chains.scroll.id]: {
    chain: 'SCROLL',
    token: 'scroll_eth'
  },
  [chains.optimism.id]: {
    chain: 'OPTIMISM',
    token: 'op_eth'
  },
  [chains.linea.id]: {
    chain: 'LINEA',
    token: 'linea_eth'
  },
  [chains.b3.id]: {
    chain: 'B3',
    token: 'ethereum'
  },
  [chains.duckChainTest.id]: {
    chain: 'DuckChainTest'.toLocaleUpperCase(),
    token: 'ton'
  },
  [chains.duckChain.id]: {
    chain: 'DuckChain'.toLocaleUpperCase(),
    token: 'ton'
  },
  [chains.ailayer.id]: {
    chain: 'AILayer'.toLocaleUpperCase(),
    token: 'btc'
  },
  [chains.bitlayer.id]: {
    chain: 'BitLayer'.toLocaleUpperCase(),
    token: 'btc'
  },
  [chains.merlin.id]: {
    chain: 'Merlin'.toLocaleUpperCase(),
    token: 'btc'
  },
  [chains.sui.id]: {
    chain: 'SUI',
    token: 'sui'
  },
  [chains.storyNetworkTestnet.id]: {
    chain: storyNetworkTestnet.name.toLocaleUpperCase(),
    token: storyNetworkTestnet.chain?.nativeCurrency.symbol.toLocaleLowerCase()
  },
  [chains.berachain_bArtioTestnet.id]: {
    chain: berachain_bArtioTestnet.name.toLocaleUpperCase(),
    token:
      berachain_bArtioTestnet.chain?.nativeCurrency.symbol.toLocaleLowerCase()
  },
  [chains.botanixTestnet.id]: {
    chain: botanixTestnet.name.toLocaleUpperCase(),
    token: botanixTestnet.chain?.nativeCurrency.symbol.toLocaleLowerCase()
  },
  [chains.worldchain.id]: {
    chain: worldchain.name.toLocaleUpperCase(),
    token: worldchain.chain?.nativeCurrency.symbol.toLocaleLowerCase()
  },
  [chains.tonTestnet.id]: {
    chain: tonTestnet.name.toLocaleUpperCase(),
    token: tonTestnet.chain?.nativeCurrency.symbol.toLocaleLowerCase()
  },
  [chains.zkSync.id]: {
    chain: zkSync.name.toLocaleUpperCase(),
    token: zkSync.chain?.nativeCurrency.symbol.toLocaleLowerCase()
  },
  [chains.b2network.id]: {
    chain: b2network.name.toLocaleUpperCase(),
    token: b2network.chain?.nativeCurrency.symbol.toLocaleLowerCase()
  },
  [chains.opbnb.id]: {
    chain: opbnb.name.toLocaleUpperCase(),
    token: opbnb.chain?.nativeCurrency.symbol.toLocaleLowerCase()
  },
  [chains.gravity.id]: {
    chain: gravity.name.toLocaleUpperCase(),
    token: gravity.chain?.nativeCurrency.symbol.toLocaleLowerCase()
  },
  [chains.sei.id]: {
    chain: sei.name.toLocaleUpperCase(),
    token: sei.chain?.nativeCurrency.symbol.toLocaleLowerCase()
  },
  [chains.tacTestnet.id]: {
    chain: tacTestnet.name.toLocaleUpperCase(),
    token: tacTestnet.chain?.nativeCurrency.symbol.toLocaleLowerCase()
  },
  // [chains.mevmTestnet.id]: {
  //   chain: mevmTestnet.name.toLocaleUpperCase(),
  //   token: mevmTestnet.chain?.nativeCurrency.symbol.toLocaleLowerCase()
  // },
  [chains.promTestnet.id]: {
    chain: promTestnet.name.toLocaleUpperCase(),
    token: promTestnet.chain?.nativeCurrency.symbol.toLocaleLowerCase()
  },
  [chains.neox.id]: {
    chain: neox.name.toLocaleUpperCase(),
    token: neox.chain?.nativeCurrency.symbol.toLocaleLowerCase()
  },
  [chains.bob.id]: {
    chain: bob.name.toLocaleUpperCase(),
    token: bob.chain?.nativeCurrency.symbol.toLocaleLowerCase()
  },
  [chains.lorenzo.id]: {
    chain: lorenzo.name.toLocaleUpperCase(),
    token: lorenzo.chain?.nativeCurrency.symbol.toLocaleLowerCase()
  },
  [chains.corn.id]: {
    chain: corn.name.toLocaleUpperCase(),
    token: corn.chain?.nativeCurrency.symbol.toLocaleLowerCase()
  },
  // [chains.sepolia.id]: {
  //   chain: 'SEPOLIA',
  //   token: 'ethereum'
  // }
  [chains.doge.id]: {
    chain: 'DOGE',
    token: doge.chain?.nativeCurrency.symbol.toLocaleLowerCase()
  }
}

export const marketChainIds = Object.keys(marketChain).map((key) => Number(key))

export const marketChainNames = marketChainIds.map((chainid) => {
  return marketChain[chainid].chain
})

export const getMarketChainId = (chainName: string) => {
  return marketChainIds.find((chainid) => {
    return marketChain[chainid].chain === chainName
  })
}

const notSupportSwapChains = [
  chains.b3?.id,
  chains.duckChainTest?.id,
  chains.duckChain?.id,
  chains.ailayer?.id,
  chains.merlin?.id,
  chains.worldchain?.id,
  chains.bitlayer?.id,
  chains.storyNetworkTestnet?.id,
  chains.berachain_bArtioTestnet?.id,
  chains.botanixTestnet?.id,
  chains.tonTestnet?.id,
  chains.zkSync?.id,
  chains.opbnb?.id,
  chains.b2network?.id,
  chains.sei?.id,
  chains.lorenzo?.id,
  // chains.mevmTestnet.id,
  chains.promTestnet?.id,
  chains.neox?.id,
  chains.bob?.id,
  chains.tacTestnet?.id,
  chains.doge?.id,
  chains.corn?.id,
  chains.gravity?.id,
  chains.cosmos?.id
]
const sortChainsArr = [
  allChain.id,
  btc.id,
  solana.id,
  ethereum.id,
  base.id,
  bsc.id,
  arbitrum.id,
  optimism.id
]
export function sortChains(chains: IWeb3ChainType[]) {
  return chains.sort((a, b) => {
    const indexA = sortChainsArr.indexOf(a.id)
    const indexB = sortChainsArr.indexOf(b.id)

    if (indexA === -1) return 1
    if (indexB === -1) return -1

    return indexA - indexB
  })
}
export const supportSwapChains = sortChains(
  allChains.filter((chain) => {
    return (
      chain &&
      chain?.type !== 'ALL' &&
      typeof chain?.chain?.id === 'number' &&
      !notSupportSwapChains.includes(chain?.chain?.id) &&
      (chain?.type === 'EVM' ||
        chain.type === 'SOL' ||
        chain.type === 'TRON' ||
        chain.type === 'BTC' ||
        chain.type === 'TON' ||
        chain.type === 'SUI')
    )
  })
)

// export const OKXchainslist = [chains.b3, chains.ailayer, chains.bsc]
export const UNSUPPROT_EVM_CHAIN: number[] = [
  chains.b3.id,
  chains.duckChainTest.id,
  chains.worldchain.id,
  chains.duckChain.id,
  chains.ailayer.id,
  chains.storyNetworkTestnet?.id,
  chains.berachain_bArtioTestnet?.id,
  chains.botanixTestnet?.id,
  chains.b2network?.id,
  chains.gravity?.id,
  chains.opbnb?.id,
  // chains.mevmTestnet?.id,
  chains.corn?.id,
  chains.promTestnet?.id,
  chains.neox?.id,
  // chains.bob?.id,
  chains.sei?.id,
  chains.tacTestnet?.id,
  chains.lorenzo?.id,
  chains.bscTestnet.id
]
const env = import.meta.env.VITE_NODE_ENV
export const UNSUPPROT_HISTORY_CHAIN = [
  ...UNSUPPROT_EVM_CHAIN,
  chains.ton.id,
  chains.cosmos.id
]

// TODO: need add other evm chain
export const GIFT_SUPPORT_CHAINS = [
  chains.solana.id,
  bsc.id,
  base.id,
  scroll.id,
  doge.id,
  env === 'development' ? tonTestnet.id : ton.id,
  ...(import.meta.env.VITE_NODE_ENV === 'production'
    ? [
        chains.ethereum.id,
        arbitrum.id,
        optimism.id,
        linea.id,
        chains.duckChain.id,
        chains.bitlayer.id,
        chains.merlin.id,
        chains.polygon.id,
        chains.b2network.id,
        chains.b3.id,
        chains.gravity.id,
        chains.blast.id,
        chains.corn.id,
        chains.botanixTestnet.id,
        chains.berachain_bArtioTestnet.id,
        chains.ailayer.id
      ]
    : [])
]

// zkSync
export const onRampChain = {
  [chains.btc.id]: {
    chain: 'BTC',
    token: 'bitcoin'
  },
  [chains.ethereum.id]: {
    chain: 'ETH',
    token: 'ethereum'
  },
  [chains.solana.id]: {
    chain: 'SOL',
    token: 'solana'
  },
  [chains.ton.id]: {
    chain: 'TON',
    token: 'ton'
  },
  [chains.tron.id]: {
    chain: 'TRX',
    token: 'tron'
  },
  [chains.bsc.id]: {
    chain: 'BSC',
    token: 'bnb'
  },
  [chains.arbitrum.id]: {
    chain: 'ARBITRUM',
    token: 'arb_eth'
  },
  // [chains.base.id]: {
  //   chain: 'BASE',
  //   token: 'base_eth'
  // },
  // [chains.blast.id]: {
  //   chain: 'BLAST',
  //   token: 'blast'
  // },
  [chains.avalanche.id]: {
    chain: 'AVAX',
    token: 'avax'
  },
  [chains.polygon.id]: {
    chain: 'MATIC',
    token: 'matic'
  },
  // [chains.scroll.id]: {
  //   chain: 'SCROLL',
  //   token: 'scroll_eth'
  // },
  [chains.optimism.id]: {
    chain: 'OPTIMISM',
    token: 'op_eth'
  }
  // [chains.linea.id]: {
  //   chain: 'LINEA',
  //   token: 'linea_eth'
  // },
  // [chains.b3.id]: {
  //   chain: 'B3',
  //   token: 'ethereum'
  // },
  // [chains.duckChainTest.id]: {
  //   chain: 'DuckChainTest',
  //   token: 'ton'
  // },
  // [chains.duckChain.id]: {
  //   chain: 'DuckChain',
  //   token: 'ton'
  // },
  // [chains.ailayer.id]: {
  //   chain: 'AILayer',
  //   token: 'btc'
  // },
  // [chains.bitlayer.id]: {
  //   chain: 'BitLayer',
  //   token: 'btc'
  // },
  // [chains.merlin.id]: {
  //   chain: 'Merlin',
  //   token: 'btc'
  // },
  // [chains.sui.id]: {
  //   chain: 'SUI',
  //   token: 'sui'
  // }
  // [chains.sepolia.id]: {
  //   chain: 'SEPOLIA',
  //   token: 'ethereum'
  // }
}

export const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000'
const configChains = chains
export default configChains

export const popularChains = [btc, ethereum, solana, bsc]
