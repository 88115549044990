import { useEffect, useState, Fragment, useCallback } from 'react'
import { usePurePopup } from '../useProgressPopup'
import { useAtom, useAtomValue, useSetAtom } from 'jotai'
import useRiskToken from '@/hooks/risk/useRiskToken'
import {
  riskTypes,
  riskModalTypes,
  ruleColors,
  capitalCase
} from '@/hooks/risk/useRiskToken'
import { riskModalDataAtom, IRiskModalData } from '@/state/modal'
import { TIcon, TButton, useTranslation } from '@/components/tmd'
import { renderStringWithComponents } from '@/utils/dom'
import { colors } from '@/components/tmd/config/theme'
import { riskSvg } from '@/assets'

export const useRiskModalComp = () => {
  const [modalData, setModalData] = useAtom(riskModalDataAtom)

  const closeModal = () => {
    setModalData(null)
  }

  const { setOpen, component: modalComp } = usePurePopup({
    content: <RiskModalComp closeModal={closeModal} />,
    enableMaskClick: true,
    onClose: closeModal,
    classNames: 'bg-bg1'
  })

  useEffect(() => {
    setOpen(!!modalData)
  }, [modalData, setOpen])

  return { modalComp }
}

export const useRiskModalController = () => {
  const setModalData = useSetAtom(riskModalDataAtom)

  const openModal = useCallback(
    (data: IRiskModalData) => {
      if (
        (data.markType && data?.rules?.length) ||
        data.markType === riskTypes.unlisted
      ) {
        setModalData(data)
      }
    },
    [setModalData]
  )

  const closeModal = useCallback(() => {
    setModalData(null)
  }, [setModalData])

  return { openModal, closeModal }
}

const RiskModalComp = ({ closeModal }: { closeModal: () => void }) => {
  const { t } = useTranslation()
  const modalData = useAtomValue(riskModalDataAtom)

  if (!modalData) return null

  const isMarkType = (type: riskTypes) => {
    return modalData?.markType === type
  }

  const getDescTotalCount = () => {
    return modalData.rules.reduce((prev, cur) => {
      return prev + cur.list.length
    }, 0)
  }

  const getAllDescList = () => {
    return modalData.rules.reduce((prev, cur) => {
      return prev.concat(cur.list)
    }, [] as string[])
  }

  const isSingleRiskDesc = () => {
    return modalData.markType && getDescTotalCount() === 1
  }

  const getTitle = () => {
    let title = ''
    if (modalData.markType === riskTypes.unlisted) {
      title = 'Token not listed'
    } else if (isSingleRiskDesc()) {
      title = getAllDescList()[0]
    } else if (isMarkType(riskTypes.risk)) {
      title = renderStringWithComponents(t('risk.isRiskToken'), {
        $1: modalData.token
      }).join('')
    } else if (isMarkType(riskTypes.warn)) {
      title = renderStringWithComponents(t('risk.isWarnToken'), {
        $1: modalData.token
      }).join('')
    }
    return title
  }

  const genIconColor = () => {
    return modalData.markType === riskTypes.unlisted
      ? ruleColors.unlisted
      : isMarkType(riskTypes.risk)
        ? ruleColors.risk
        : ruleColors.warn
  }

  const onCancelClick = () => {
    if (typeof modalData.onCancel === 'function') {
      modalData.onCancel()
    }
    closeModal()
  }

  const onGotItClick = () => {
    if (typeof modalData.onGotIt === 'function') {
      modalData.onGotIt()
    }
    closeModal()
  }

  const onContinueClick = () => {
    if (typeof modalData.onContinue === 'function') {
      modalData.onContinue()
    }
  }

  return (
    <div className="flex w-full flex-col pb-[26px] pt-2 font-['Switzer']">
      <div className="flex justify-center" style={{ color: genIconColor() }}>
        <TIcon name="tg_wallet_risk" fontSize="56" />
      </div>
      <div className="mt-4 text-center">
        <span className="text-[20px] font-semibold text-t1">{getTitle()}</span>
      </div>
      <div className="mt-2 text-center">
        <span className="text-[12px] text-t3">
          {modalData.markType === riskTypes.unlisted
            ? t('risk.notListedTokenDesc')
            : t('risk.warnTokenDesc')}
        </span>
      </div>
      {modalData.rules.length > 1 && (
        <div className="mb-4 mt-6 w-full border-b-[0.5px] border-b-l1" />
      )}
      {isSingleRiskDesc() ? null : (
        <div className="mb-4">
          {modalData.rules.map((v, i) => (
            <div
              key={i}
              className={`${i !== modalData.rules.length - 1 ? 'mb-4' : ''}`}
            >
              <div
                className={`text-[${
                  ruleColors[v.type]
                }] mb-2 text-[16px] font-medium`}
              >
                <span>{`${v.list.length} ${capitalCase(v.typeText)}`}</span>
              </div>
              {v.list.map((text: string, index: number) => (
                <div
                  key={index}
                  className={`${
                    index !== v.list.length - 1 ? 'mb-2' : ''
                  } flex w-full items-center rounded-[4px] bg-bg3 p-2`}
                >
                  <TIcon
                    name="tg_wallet_risk"
                    fontSize="14"
                    className={`text-[${ruleColors[v.type]}] mr-2`}
                  />
                  <span className="text-[12px] font-medium">
                    <span className={`text-[${ruleColors[v.type]}]`}>
                      {modalData.token}
                    </span>
                    <span className="text-t1"> {text}</span>
                  </span>
                </div>
              ))}
            </div>
          ))}
        </div>
      )}
      <div className="mb-8 flex h-[18px] items-center">
        <div className="mr-2 text-xs text-t3">Powered by</div>
        <img
          style={{ transform: 'translateY(-1px)' }}
          src={riskSvg.riskPoweredByIcon}
          alt=""
        />
      </div>
      <div className={modalData.rules.length === 1 ? 'mt-8' : ''}>
        {modalData.modalType === riskModalTypes.confirm ? (
          <Fragment>
            <TButton className="mb-3 h-[52px] w-full" onClick={onCancelClick}>
              {t('tg_wallet.cancel')}
            </TButton>
            <TButton
              className="h-[52px] w-full"
              onClick={onContinueClick}
              theme="ghost"
            >
              {t('tg_wallet.continue')}
            </TButton>
          </Fragment>
        ) : (
          <TButton className="h-[52px] w-full" onClick={onGotItClick}>
            {t('tg_wallet.got_it')}
          </TButton>
        )}
      </div>
      {/* <div style={{ display: 'none' }}>
        <span className="text-[#EB4B6D]" />
        <span className="text-[#FF9142]" />
      </div> */}
    </div>
  )
}
