import { AssetsToken } from '@/stores/tokenStore/type/AssetsToken'
import { BaseChain } from '../tokenStore/type/BaseChain'
import {
  IChainId,
  IWeb3ChainType,
  Web3Type
} from '@/proviers/web3Provider/type'
import configChains from '@/proviers/web3Provider/chains'
import { solScanUrl, solTokenScanUrl } from 'config/sol'
import {
  mockTonChainId,
  mockTonOkxChainID,
  tonAddressScanUrl,
  tonScanUrl
} from 'config/ton'
import { tronScanUrl, tronTokenScanUrl } from 'config/tron'
import { IHistoryType, ReportSourceType } from '@/state'
import { cosmosScanUrl } from 'config/cosmos'
import tokenStore from '@/stores/tokenStore'
import { TransactionsType } from './type'
import {
  CURRENT_CACHE_VERSION,
  getCache,
  setCache,
  STORAGE_KEY
} from '@/utils/cacheManage'
import {
  postSwapPoint,
  resourceReportPost,
  txReportPost,
  postSendPoint
} from 'api'
import { formatUnits, GetTransactionReceiptReturnType } from 'viem'
import { suiScanUrl, suiTokenScanUrl } from '@/config/sui'
import { btcAddressScan, btcScan } from '@/config/btc'
import { initUserInfo, userChainAddressList } from '../userStore/utils'
import userStore from '../userStore'
import { UserType } from '../userStore/type'
import { SUI_TYPE_ARG } from '@mysten/sui/utils'
import {
  tonScanAddressTestnetUrl,
  tonScanTestnetUrl
} from '@/config/tonTestnet'
import {
  sendFaildToast,
  sendSuccessToast,
  SendSuccessType,
  swapFaildToast,
  swapSuccessToast
} from '@/pages/token/sendToken/utils/sendToast'
import dayjs from 'dayjs'
import { Aggregator } from '@/constants/types'
import { dogeScanUrl } from '@/config/doge'

export const HIDDEN_TOKENS = '__HIDDEN_TOKENS'
export const SHOW_TOKENS = '__SHOW_TOKENS'

export function iChainToBaseChain(
  iChain: IWeb3ChainType,
  displayName?: string | undefined
) {
  if (!iChain.chain || !iChain.chain.id) {
    throw new Error('iChainToBaseChain function: chain need')
  }
  return {
    chainId: iChain.chain?.id,
    type: iChain.type,
    icon: iChain.icon,
    decimals: iChain.chain?.nativeCurrency.decimals,
    symbol: iChain.chain?.nativeCurrency.symbol,
    name: iChain.chain?.nativeCurrency.name,
    displayName: displayName ?? ''
  } as BaseChain
}

export const getScanUrl = ({
  chain,
  hash,
  hashTest
}: {
  chain: IWeb3ChainType | undefined
  hash: string
  hashTest: string
}) => {
  switch (chain?.type) {
    case 'BTC':
      return btcScan + hash
    case 'EVM':
      return chain?.chain?.blockExplorers?.default.url + '/tx/' + hash
    case 'SOL':
      return solScanUrl + hash
    case 'TON':
      return tonScanUrl + hash
    case Web3Type.TONTEST:
      return tonScanTestnetUrl + hashTest
    case 'TRON':
      return tronScanUrl + hash
    case 'SUI':
      return suiScanUrl + hash
    case 'COSMOS':
      return cosmosScanUrl + hash
    case 'DOGE':
      return dogeScanUrl + hash
    default:
      return undefined
  }
}

export const getTokenScanUrl = ({
  chain,
  address
}: {
  chain: IWeb3ChainType | undefined
  address: string
}) => {
  switch (chain?.type) {
    case 'BTC':
      return btcAddressScan + address
    case 'EVM':
      return chain?.chain?.blockExplorers?.default.url + '/token/' + address
    case 'SOL':
      return solTokenScanUrl + address
    case 'TON':
      return tonAddressScanUrl + address
    case Web3Type.TONTEST:
      return tonScanAddressTestnetUrl + address
    case 'TRON':
      return tronTokenScanUrl + address
    case 'SUI':
      return suiTokenScanUrl + address
    default:
      return undefined
  }
}

export const handleTokenAddrExplorer = (
  chain: IWeb3ChainType | undefined,
  address: string | undefined
) => {
  if (!address) return
  const chainScanTxUrl = getTokenScanUrl({
    chain,
    address
  })
  if (chainScanTxUrl && window?.Telegram?.WebApp) {
    window.Telegram.WebApp.openLink(chainScanTxUrl)
  }
}

export const getChainByChainIdV2 = ({
  chainId,
  chains
}: {
  chainId: number | string
  chains: IWeb3ChainType[] | undefined
}) => {
  if (!chains) return

  const calcChainId = (() => {
    if (
      Number(chainId) === mockTonOkxChainID ||
      Number(chainId) === mockTonChainId
    ) {
      return mockTonChainId
    }

    return chainId
  })()

  const chain = chains.find((c) => c.id === Number(calcChainId))
  // const chain = Object.values(chains).find((c) => c.id === Number(chainId))
  return chain
}

export const getChainByToken = (
  token: AssetsToken,
  chains: IWeb3ChainType[] | undefined
) => {
  const tokenChainId = token.chainId
  return getChainByChainIdV2({
    chainId: tokenChainId,
    chains
  })
}

export const getSwapChainId = (chainId: IChainId | undefined) => {
  return chainId === configChains.ton.id ? mockTonOkxChainID : chainId
}

export const marketTokenLink = ({
  chainId,
  address,
  chain,
  chains,
  token
}: {
  chainId: number
  address?: string
  chain?: string
  token?: string
  chains: IWeb3ChainType[] | undefined
}) => {
  if (
    getChainByChainIdV2({
      chainId,
      chains
    })?.id === -1
  ) {
    return ''
  }
  if (!chain || (!address && !token)) {
    return ''
  }
  const chainNameUrl = chain
  const tokenUrl = address ? `${chainNameUrl}-${address}` : token

  return `${chainNameUrl}/${tokenUrl}`
}

export const sortByPriceBalance = (income: AssetsToken[]) => {
  const list = [...income]
  list.sort((a, b) => {
    const aFormatted = Number(a.formatted) * Number(a.price)
    const bFormatted = Number(b.formatted) * Number(b.price)
    return bFormatted - aFormatted
  })
  return list
}

export const tokenMappings = (income: AssetsToken[]) => {
  return income.reduce(
    (map, item) => {
      map[item.id] = item
      return map
    },
    {} as Record<string, AssetsToken>
  )
}

export const findToken = (
  tokenMappings: Record<string, AssetsToken>,
  item: {
    chainId: number
    symbol: string
    address?: string
  }
) => {
  if (item.symbol) {
    return (
      tokenMappings[`${item.symbol}_${item.address}_${item.chainId}`] ||
      tokenMappings[`${item.symbol}__${item.chainId}`]
    )
  }
  return undefined
}

/**txs helper*/
export const getWalletTransactionsKey = () => {
  let walletId = null
  const userStr = localStorage.getItem(STORAGE_KEY.user)
  if (userStr && userStr !== 'null') {
    const userInfo = JSON.parse(userStr)
    walletId = userInfo?.['defaultWalletId'] ?? -1
    return `${STORAGE_KEY.TRANSACTIONS}_${walletId}`
  }
  return null
}

export const txsFilter = (
  txs: TransactionsType,
  selectFunc: (item: IHistoryType) => void
) => {
  return Object.keys(txs)
    .map((key) => {
      const intKey = Number(key) as IChainId
      return txs[intKey]
    })
    .filter((item) => !!item)
    .flat()
    .filter(selectFunc)
}

const TransferToAssetsToken = (income: any) => {
  if (income.source) return income
  const address = income?.contract ?? income?.address ?? ''
  const assets: AssetsToken = {
    isNative: income?.is_native,
    isToken: !income?.is_native,
    chainId: income.chain_id,
    decimals: income.decimals,
    symbol: income.symbol,
    name: income.name ?? income.symbol,
    address,
    balance: '0',
    price: income.price,
    image: income.image,
    source: 'history',
    id: `${address}-${income.chain_id}-${income.symbol}`,
    formatted: '0',
    whiteToken: undefined,
    customToken: undefined
  }
  return assets
}

export const RecordProviders: {
  [key in Aggregator]?: string
} & {
  [key in string]: string
} = {
  [Aggregator.JUPITER]: Aggregator.JUPITER,
  [Aggregator.OKX]: 'OKX',
  OKX: 'OKX',
  [Aggregator.RANGO]: Aggregator.RANGO,
  [Aggregator.STONFI]: 'Stonfi'
}

export const pendingChangedForSwap = async (tx: IHistoryType) => {
  if (tx.historyType !== 'Swap') return
  // if (tx.status !== 'success') return

  const amount = BigInt(tx.fromAmount ?? '0')
  const decimals = tx.fromSwapTokens?.token?.decimals ?? 18
  const formatted = formatUnits(amount, decimals)
  const price = +formatted * (tx.fromSwapTokens?.token?.price ?? 1)
  const feeRate = userStore.swapFeeAddress.feeRate
  const parmas = {
    fromToken: tx.fromSwapTokens?.token?.symbol ?? '',
    fromChain: tx.fromSwapTokens?.token?.chain ?? '',
    fromContract: tx.fromSwapTokens?.token?.address ?? '',
    toToken: tx.toSwapTokens?.token?.symbol ?? '',
    toChain: tx.toSwapTokens?.token?.chain ?? '',
    toContract: tx.toSwapTokens?.token?.address ?? '',
    amount: formatted,
    priceUsd: price.toString(),
    txHash: tx.hash as string,
    refundRate: feeRate,
    providerType: tx.type
      ? RecordProviders[tx.type as Aggregator] || tx.type
      : tx.type,
    sourceChainId: tx.fromSwapTokens?.chain?.id.toString(),
    targetChainId: tx.toSwapTokens?.chain?.id.toString(),
    rangoRequestId: tx.requestId
  }
  console.log({
    parmas,
    tx,
    key: 'pendingChangedForSwap'
  })
  postSwapPoint(parmas)
}

export const pendingChangedForSend = async (
  tx: IHistoryType,
  user: UserType
) => {
  if (tx.historyType !== 'Send') return
  // if (tx.status !== 'success') return

  const amount = BigInt(tx.fromAmount ?? '0')
  const decimals = tx.fromSwapTokens.token.decimals || 18
  const formatted = formatUnits(amount, decimals)
  const price = +formatted * (tx.fromSwapTokens.token.price || 1)

  const parmas = {
    chainId: tx.chain?.id.toString() || '',
    txId: tx.hash,
    senderUserId: user.id,
    senderAddress: tx.fromAddress || '',
    receiverAddress: tx.toAddress || '',
    amount: amount.toString(),
    tokenContract: tx.fromSwapTokens.token.address,
    decimals: decimals,
    symbol: tx.fromSwapTokens.token.symbol,
    priceUsd: price
  }
  postSendPoint(parmas)
}

//

export const getTxsList = () => {
  const key = getWalletTransactionsKey()
  if (!key) return {}

  const txs: TransactionsType = getCache(key, {})
  for (const chainId in txs) {
    const list = txs[chainId] as IHistoryType[]
    list.forEach((history) => {
      if (history.fromSwapTokens) {
        history.fromSwapTokens.token = TransferToAssetsToken(
          history.fromSwapTokens.token
        )
      }
      if (history.toSwapTokens) {
        history.toSwapTokens.token = TransferToAssetsToken(
          history.toSwapTokens.token
        )
      }
    })
  }
  return txs
}

export const sortByTime = (txs: TransactionsType) => {
  for (const txChainId in txs) {
    const chainId = Number(txChainId) as IChainId
    const txChainList = txs[chainId]
    txChainList?.sort((a, b) => b.time - a.time)
    txs[chainId] = txChainList
  }
}

export const setTxsList = (income: any) => {
  const key = getWalletTransactionsKey()
  if (!key) return
  for (const chainId in income) {
    const list: IHistoryType[] = income[chainId]
    list.forEach((history) => {
      if (history.fromSwapTokens) {
        history.fromSwapTokens.token = TransferToAssetsToken(
          history.fromSwapTokens.token
        )
      }
      if (history.toSwapTokens) {
        history.toSwapTokens.token = TransferToAssetsToken(
          history.toSwapTokens.token
        )
      }
    })
  }
  setCache(key, income)
}

export const mergeIncomeData = (
  localData: TransactionsType,
  incomeData: TransactionsType
) => {
  const temp: TransactionsType = {}
  const keys = [...Object.keys(localData), ...Object.keys(incomeData)]
  for (const txChainId of keys) {
    const chainId = Number(txChainId) as IChainId
    const txChainLocal = localData[chainId] || []
    const txChainDB = incomeData[chainId] || []
    const holder = new Set()
    const mergeRes: IHistoryType[] = []
    const mergeList = [...txChainLocal, ...txChainDB]
    mergeList.forEach((iHistory) => {
      if (!holder.has(iHistory.hash)) {
        mergeRes.push(iHistory)
        holder.add(iHistory.hash)
      }
    })
    temp[chainId] = mergeRes
  }
  return temp
}

export const txsStoreMergeByIncome = (incomeTxs: TransactionsType) => {
  if (!incomeTxs) return
  const txs: TransactionsType = getTxsList()
  const merged = mergeIncomeData(txs, incomeTxs)
  sortByTime(merged)
  return merged
}

export const addSignleTx = (
  txs: TransactionsType,
  history: IHistoryType,
  chainId: IChainId
) => {
  const temp: TransactionsType = { ...txs }
  //check if give a wrong data
  if (!history) return
  if (!chainId && chainId !== 0) return
  if (!history.hash) return
  //check if find
  const finds = txsFilter(temp, (iHistory) => iHistory.hash === history.hash)
  if (finds.length) return
  const txsKey = chainId as IChainId
  const items = temp[txsKey] ?? []
  temp[txsKey] = [history as IHistoryType, ...(items ? items : [])]
  sortByTime(temp)
  return temp
}

export const updateSignleTx = (
  txs: TransactionsType,
  history: IHistoryType
) => {
  if (!history) return
  const temp: TransactionsType = { ...txs }
  let findFlag = false
  for (const txChainId in temp) {
    const chainId = Number(txChainId) as IChainId
    const txChainList = temp[chainId]
    txChainList?.forEach((iHistoryType, idx) => {
      if (iHistoryType.hash === history.hash) {
        findFlag = true
        txChainList[idx] = history
      }
    })
  }
  if (!findFlag) {
    return addSignleTx(txs, history, history.chain?.id || -1)
  }
  sortByTime(temp)
  return temp
}

export const mergeTxs = (oldTxs: TransactionsType, neTxs: TransactionsType) => {
  const arr = txStatusChange(oldTxs, neTxs)
  // arr.forEach((i) => i.changed && sendHistoryToast(i.changed))
  if (!Object.keys(oldTxs).length) return neTxs
  const temp: TransactionsType = { ...oldTxs }
  for (const txChainId in neTxs) {
    const chainId = Number(txChainId) as IChainId
    const txChainList = neTxs[chainId]
    txChainList?.forEach((history, idx) => {
      let oldChainList = temp[txChainId]
      if (!oldChainList) {
        temp[txChainId] = []
        oldChainList = temp[txChainId]
      }
      const finds = txsFilter(
        temp,
        (iHistory) => iHistory.hash === history.hash
      )
      if (finds?.length) {
        const findIndex = temp[txChainId]?.findIndex(
          (o) => o.hash === history.hash
        ) as number
        oldChainList[findIndex] = {
          ...history,
          status:
            history.status === 'success' || finds[0].status === 'success'
              ? 'success'
              : history.status,
          gasAmount: history.gasAmount || finds[0].gasAmount
        }
      } else {
        oldChainList.push(history)
      }
    })
  }
  return temp
}

export const findTxs = (txs: TransactionsType, hash: string) => {
  return Object.values(txs)
    .flat()
    .find((tx: any) => tx?.hash === hash)
}

export const txStatusChange = (
  txsOld: TransactionsType,
  txsNew: TransactionsType
) => {
  const temp: {
    current: IHistoryType | undefined
    changed: IHistoryType | undefined
  }[] = []
  Object.values(txsOld)
    .flat()
    .forEach((t) => {
      const txNew = findTxs(txsNew, t?.hash || '')
      if (txNew && t?.status !== txNew.status) {
        temp.push({
          current: t,
          changed: txNew
        })
      }
    })
  return temp
}

export const sendHashToast = ({
  type,
  status,
  fromAmount,
  toAmount,
  fromSymbol,
  toSymbol
}: {
  type: 'Send' | 'Swap'
  status: 'success' | 'failed'
  fromAmount: string
  toAmount: string
  fromSymbol: string
  toSymbol: string
}) => {
  const data: SendSuccessType = {
    amount: fromAmount || '-',
    symbol: fromSymbol || ''
  }
  if (type === 'Send') {
    const searchParams = new URLSearchParams(window.location.search)
    const amount = searchParams.get('amount') || ''
    const symbol = searchParams.get('symbol') || ''
    const sendData = {
      amount: amount || '-',
      symbol: symbol || ''
    }
    if (status === 'success' && fromAmount && fromSymbol) {
      sendSuccessToast(sendData)
    }
    if (status === 'failed' && fromAmount && fromSymbol) {
      sendFaildToast(sendData)
    }
  } else if (type === 'Swap') {
    const swapData = {
      fromAmount: fromAmount || '-',
      fromSymbol: fromSymbol || '',
      toAmount: toAmount || '-',
      toSymbol: toSymbol || ''
    }
    if (status === 'success') {
      swapSuccessToast(swapData)
    }
    if (status === 'failed') {
      swapFaildToast(swapData)
    }
  }
}

export const initTokenList = () => {
  const user = localStorage.getItem('user')
  if (!user || user === 'null') return []
  const userObj = JSON.parse(user)
  const cachedData = localStorage.getItem(
    `__TOKENS_LIST_${userObj?.defaultWalletId ?? -1}_${userObj?.id}`
  )
  if (!cachedData) return []
  try {
    const { version, data } = JSON.parse(cachedData)
    if (version === CURRENT_CACHE_VERSION) {
      return data
    } else {
      return []
    }
  } catch (e) {
    return []
  }
}

/*
 * report token list
 **/
export const reportTokenList = (
  tokens: AssetsToken[],
  chains: IWeb3ChainType[] | undefined
) => {
  const user = initUserInfo()
  const list = tokens
    .filter((token) => {
      return (token.price || 0) * Number(token.formatted ?? 0)
    })
    .map((i) => {
      const userAddr = userChainAddressList(user, i.chainId) ?? ''
      const reportToken = {
        address: userAddr,
        amount: i.formatted,
        chainID: i.chainId,
        native: i.isNative,
        symbol: i.symbol,
        token: i.address,
        userID: user.id,
        chainName:
          i.whiteToken?.chain ||
          i.customToken?.chain ||
          getChainByChainIdV2({
            chainId: i.chainId,
            chains
          })?.name ||
          '-'
      }
      return reportToken
    })
  if (list.length) resourceReportPost(list)
}

export const getTxTokenInfo = ({
  tx,
  history,
  tokenList,
  chains
}: {
  tx: ReportSourceType
  history: IHistoryType
  tokenList: AssetsToken[]
  chains: IWeb3ChainType[] | undefined
}) => {
  const fromTokenInfo = tokenList.find(
    (o) =>
      o.address.toLowerCase() === tx.from.tokenAddress.toLowerCase() &&
      o.chainId === tx.from.chainID
  )
  let isNativeFrom = !tx.from.tokenAddress
  if (tx.from.chainID === configChains.sui.id) {
    if (tx.from.tokenAddress === SUI_TYPE_ARG) {
      isNativeFrom = true
    }
  }
  let fromToken: AssetsToken = {
    isNative: isNativeFrom,
    isToken: !isNativeFrom,
    chainId: tx.from.chainID,
    decimals: tx.from.decimals,
    symbol: tx.from.symbol,
    name: tx.from.symbol,
    address: tx.from.tokenAddress,
    balance: '',
    price: 0,
    image: '',
    source: 'all',
    id: `${tx.from.tokenAddress}-${tx.from.chainID}-${tx.from.symbol}`,
    formatted: ''
  }
  const nativeTokenInfo = tokenList.find(
    (o) => o.chainId === tx.from.chainID && o.isNative
  )
  const nativeInfo = getChainByChainIdV2({ chainId: tx.from.chainID, chains })
  const naviteCurrency = nativeInfo?.chain?.nativeCurrency
  let nativeToken: AssetsToken = {
    isNative: true,
    isToken: false,
    chainId: tx.from.chainID,
    decimals: naviteCurrency?.decimals || 18,
    symbol: naviteCurrency?.symbol || '',
    name: naviteCurrency?.name || '',
    address: '',
    balance: '',
    price: 0,
    image: '',
    source: 'all',
    id: `${tx.from.chainID}-${naviteCurrency?.symbol}`,
    formatted: ''
  }
  if (fromTokenInfo) {
    fromToken = fromTokenInfo
  }
  if (nativeTokenInfo) {
    nativeToken = nativeTokenInfo
  }
  return {
    fromTokenInfo: fromToken,
    nativeTokenInfo: nativeToken
  }
}

export const reportMixpanel = ({
  tx,
  history,
  trackSwap,
  trackSend,
  chains,
  tokenList
}: {
  tx: ReportSourceType
  history: IHistoryType
  trackSwap: any
  trackSend: any
  tokenList: AssetsToken[]
  chains: IWeb3ChainType[] | undefined
}) => {
  try {
    if (tx.status !== 'pending' && tx.status !== 'loading') {
      const fromChain = getChainByChainIdV2({
        chainId: tx.from.chainID,
        chains
      })
      const toChain = getChainByChainIdV2({ chainId: tx.to.chainID, chains })

      const catchNumber = (amount: any, decimals: number) => {
        try {
          return formatUnits(BigInt(amount), decimals)
        } catch (error) {
          return amount
        }
      }

      const fromAmount = catchNumber(tx.from.amount, tx.from.decimals) || ''
      const toAmount = catchNumber(tx.to.amount, tx.from.decimals) || ''

      const { fromTokenInfo, nativeTokenInfo } = getTxTokenInfo({
        tx,
        history,
        tokenList,
        chains
      })

      switch (history.historyType) {
        case 'Swap':
          trackSwap({
            fromChain: fromChain?.name || '',
            toChain: toChain?.name || '',
            fromTokenSymbol: tx.from.symbol || '',
            toTokenSymbol: tx.to.symbol || '',
            fromAmount: fromAmount,
            toAmount: toAmount,
            totalTokenUsd: (
              Number(fromAmount) * fromTokenInfo.price
            ).toString(), //todo get from token usd price
            providerType: tx.plat || '',
            providerTypeInfo: history.routeInfo?.swapperTitle || '',
            status: tx.status === 'success',
            fromAddress: history.fromAddress || '',
            gasUsd:
              (
                Number(history.gasAmount || '0') * nativeTokenInfo.price
              ).toString() || '' //todo get from native token usd price
          })
          break
        case 'Send':
          trackSend({
            chain: fromChain?.name || '',
            tokenSymbol: tx.from.symbol || '',
            amount: fromAmount,
            totalTokenUsd: (
              Number(fromAmount) * fromTokenInfo.price
            ).toString(), //todo get from token usd price
            status: tx.status === 'success',
            gasUsd:
              (
                Number(history.gasAmount || '0') * nativeTokenInfo.price
              ).toString() || '', //todo get from native token usd price
            fromAddress: history.fromAddress || '',
            toAddress: tx.toAddress || ''
          })
          break
        default:
          break
      }
    }
  } catch (error) {
    console.warn('track', error)
  }
}

/*
 * report tx
 **/
export const reportTx = async ({
  tx,
  plugins,
  chains
}: {
  tx: IHistoryType
  plugins?: {
    trackSwap: any
    trackSend: any
    tokenList: AssetsToken[]
  }
  chains: IWeb3ChainType[] | undefined
}) => {
  if (tx.historyType !== 'Swap' && tx.historyType !== 'Send') return
  const user = initUserInfo()
  const chainId = tx.fromSwapTokens.chain?.id ?? -1

  const source: ReportSourceType = {
    plat: tx.type || '',
    status: tx.status || '',
    sourceType:
      tx.fromSwapTokens.chain?.id !== tx.toSwapTokens.chain?.id
        ? 'cross'
        : 'normal',
    requestId: tx.requestId || '',
    time: tx.endTime || tx.time,
    from: {
      chainID: (tx.fromSwapTokens.chain as IWeb3ChainType).id,
      symbol: tx.fromSwapTokens.token.symbol,
      tokenAddress: tx.fromSwapTokens.token.address || '',
      amount: tx.fromAmount || '0',
      decimals: tx.fromSwapTokens.token.decimals
    },
    hash: tx.hash,
    to: {
      chainID: (tx.toSwapTokens.chain as IWeb3ChainType)?.id,
      symbol: tx.toSwapTokens.token.symbol,
      tokenAddress: tx.toSwapTokens.token.address || '',
      amount: tx.toAmount || '0',
      decimals: tx.toSwapTokens.token.decimals
    },
    toHash: tx.toHash || '',
    toAddress: tx.historyType === 'Send' ? tx.toAddress ?? '' : '',
    toBlock: tx.blocknumber || '',
    routeInfo: tx.routeInfo
  }

  if (plugins)
    reportMixpanel({
      tx: source,
      history: tx,
      tokenList: plugins.tokenList,
      trackSwap: plugins.trackSwap,
      trackSend: plugins.trackSend,
      chains
    })

  return await txReportPost({
    chainID: chainId,
    gas: tx.gasAmount || '',
    source: JSON.stringify(source),
    tx: tx.hash,
    type: tx.historyType === 'Swap' ? 'swap' : 'send',
    userID: user.id
  })
}

export const formatterEvmTransactionReceipt = (
  receipt: GetTransactionReceiptReturnType,
  decimals: number
) => {
  const toHash = receipt.transactionHash
  const blockNumber = receipt.blockNumber.toString()
  const gasAmount = formatUnits(receipt.gasUsed, decimals)
  const toAddress = receipt.to
  return {
    blockNumber,
    gasAmount,
    toAddress,
    endTime: '',
    toHash
  }
}

export const formatterRangoTransactionReceipt = (receipt: any) => {
  const toHash = receipt.destTxHash || receipt.srcTxHash
  return {
    blockNumber: '',
    gasAmount: '',
    toAddress: '',
    endTime: '',
    toHash
  }
}

export const formatterSolTransactionReceipt = (receipt: any) => {
  return {
    endTime: ((receipt.blockTime as number) * 1000).toString(),
    gasAmount: formatUnits(
      BigInt(receipt.meta.fee),
      configChains.solana.chain?.nativeCurrency.decimals || 9
    ),
    blockNumber: '',
    toAddress: '',
    toHash: ''
  }
}

export const recordDataByHistory = (
  iHistoryType: IHistoryType,
  user: UserType
) => {
  switch (iHistoryType.historyType) {
    case 'Swap':
      pendingChangedForSwap(iHistoryType)
      break
    case 'Send':
      pendingChangedForSend(iHistoryType, user)
      break

    default:
      break
  }
}

export const groupByDate = (txs: IHistoryType[]) =>
  txs.reduce(
    (obj, i) => {
      // const date = convertTimestampToDateText(i.time)
      const date = dayjs(i.time).format('YYYY/MM/DD')
      if (!obj[date]) {
        obj[date] = []
      }
      obj[date].push(i)
      obj[date] = obj[date].sort((a, b) => b.time - a.time)
      return obj
    },
    {} as { [key: string]: IHistoryType[] }
  )

export function getTokenUniquKey(
  token:
    | AssetsToken
    | {
        symbol: string
        chainId: number
      }
) {
  return `${token.symbol}_${token.chainId}`
}
