import { popularChains, ZERO_ADDRESS } from '@/proviers/web3Provider/chains'
import allChain from '@/proviers/web3Provider/chains/wagmiConfig/allChain'
import { IWeb3ChainType } from '@/proviers/web3Provider/type'

interface ReceiveLinkType {
  chainId: number | string
  address: string
  wtype?: string
}
export const receiveLink = ({ chainId, address, wtype }: ReceiveLinkType) => {
  return `/token/${chainId}/${address || ZERO_ADDRESS}/receive${
    wtype ? `/${wtype}` : ''
  }`
}

export const sortChainsForIndexBar = (
  chains: IWeb3ChainType[]
): Array<{ index: string; items: IWeb3ChainType[] }> => {
  const popularChainIds = popularChains.map((chain: IWeb3ChainType) => chain.id)
  const noGroupIds = [allChain.id, ...popularChainIds]

  const existsIds: number[] = []
  chains.sort((a, b) => a.name.localeCompare(b.name))

  const newPopularChains: IWeb3ChainType[] = []

  const grouped = chains.reduce(
    (acc: { [x: string]: IWeb3ChainType[] }, chain) => {
      if (noGroupIds.includes(chain.id)) {
        existsIds.push(chain.id)
        if (popularChainIds.includes(chain.id)) newPopularChains.push(chain)
        return acc
      }

      const firstLetter = chain.name[0].toUpperCase()
      if (!acc[firstLetter]) {
        acc[firstLetter] = []
      }
      acc[firstLetter].push(chain)
      return acc
    },
    {}
  )

  const result = Object.keys(grouped)
    .sort()
    .map((letter) => ({
      index: letter,
      items: grouped[letter]
    }))

  const allItems = existsIds.includes(allChain.id)
    ? [{ index: 'all', items: [allChain] }]
    : []

  const popularChainsItems =
    newPopularChains.length > 0
      ? [{ index: 'popular', items: newPopularChains }]
      : []

  return [...allItems, ...popularChainsItems, ...result]
}
