import {
  receiveToRampDark,
  receiveToRampLight,
  visaSvg,
  applePay,
  mastercardSvg,
  applyPayDark,
  googlePaylight,
  googlePayDark
} from '@/assets'
import useTheme from '@/stores/userStore/hooks/useTheme'
import IntroduceCard from './IntroduceCard'

export const SupportPayTypeList = () => {
  const { theme } = useTheme()
  const lightTheme = theme === 'light'
  const payTypeIconList = [
    {
      key: 'masterCard',
      icon: <img src={mastercardSvg} />
    },
    {
      key: 'visa',
      icon: <img src={visaSvg} />
    },
    {
      key: 'applePay',
      icon: (
        <img
          className="apple-pay-img"
          src={lightTheme ? applePay : applyPayDark}
          alt=""
        />
      )
    },
    {
      key: 'googlePay',
      icon: (
        <img
          className="google-pay-img"
          src={lightTheme ? googlePaylight : googlePayDark}
          alt=""
        />
      )
    }
  ]

  return (
    <div className="pay-type-list space-between flex size-full gap-[8px]">
      {payTypeIconList.map((item) => (
        <div
          className="pay-type-list-item box-border flex  flex-1 items-center justify-center bg-bg3"
          key={item.key}
        >
          {item.icon}
        </div>
      ))}
    </div>
  )
}

const CurrentcyBuyCard = ({
  classNames,
  title
}: {
  classNames?: string
  title?: string
}) => {
  const { theme } = useTheme()
  const lightTheme = theme === 'light'
  return (
    <IntroduceCard
      img={lightTheme ? receiveToRampLight : receiveToRampDark}
      classNames={classNames}
      title={title}
      content={'Use fiat currency to buy crypto'}
      desc={<SupportPayTypeList />}
    />
  )
}

export default CurrentcyBuyCard
