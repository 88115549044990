import { classNames } from '@/utils'
import { TSkeleton as Skeleton } from '@/components/tmd'

const SkeletonWallet = () => {
  return (
    <div className="px-5">
      <div className="flex items-center justify-between pt-1.5">
        <Skeleton
          style={{
            '--height': '36px',
            '--width': '36px',
            '--border-radius': '999px'
          }}
        />
        <Skeleton style={{ '--height': '24px', '--width': '108px' }} />
      </div>

      <Skeleton
        className="mt-6"
        style={{
          '--height': '22px',
          '--width': '179px'
        }}
      />
      <Skeleton
        className="mt-[10px]"
        style={{
          '--height': '43px',
          '--width': '164px'
        }}
      />

      <Skeleton
        className="mt-2"
        style={{
          '--height': '50px',
          '--width': '40vw'
        }}
      />

      <div className="mt-10 grid grid-cols-5 gap-6">
        <Skeleton style={{ '--height': '70px' }} />
        <Skeleton style={{ '--height': '70px' }} />
        <Skeleton style={{ '--height': '70px' }} />
        <Skeleton style={{ '--height': '70px' }} />
        <Skeleton style={{ '--height': '70px' }} />
      </div>

      <Skeleton className="mt-6" style={{ '--height': '48px' }} />

      <div className="mt-4 grid grid-cols-1 gap-8">
        {Array.from({ length: 5 }, (_, index) => index).map((index) => (
          <Skeleton key={index} style={{ '--height': '41px' }} />
        ))}
      </div>
    </div>
  )
}

export default SkeletonWallet
