import React from 'react'

interface IMap {
  [key: number | string]: (() => React.ReactNode | JSX.Element | string) | string
}

export const renderStringWithComponents = (str: string, componentMap: IMap) => {
  const regex = /{{\$(\d+)}}/g
  const parts = []
  let lastIndex = 0

  let match
  while ((match = regex.exec(str)) !== null) {
    const placeholder = match[0]
    const componentKey = `$${match[1]}`
    
    parts.push(str.slice(lastIndex, match.index))
    
    if (componentMap[componentKey]) {
      const item = typeof componentMap[componentKey] === 'string'
        ? componentMap[componentKey]
        : React.createElement(componentMap[componentKey])
      parts.push(item)
    } else {
      parts.push(placeholder)
    }
    
    lastIndex = regex.lastIndex
  }

  parts.push(str.slice(lastIndex))

  return parts
}
