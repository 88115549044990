import QRCode from 'react-qr-code'
import {
  TCopy as Copy,
  Loading,
  TContainer,
  TIcon,
  TTokenImage
} from 'components/tmd'
import { Button } from 'components/Button'
import CopyToClipboard from 'react-copy-to-clipboard'
import useLoginInfo from 'hooks/useLoginInfo'
import { useEffect, useMemo, useRef } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import usePageSetting from 'hooks/usePageSetting'
import useChains from '@/stores/tokenStore/hooks/useChains'
import walletStore from '@/stores/walletStore'
import { useTokenSearch } from 'hooks/api/useMarketApis'
import { zeroAddress } from 'viem'
import { btcTypeMaps, isBtc } from 'utils'
import { toast as rToast, Id as RId } from 'react-toastify'
import { AssetsToken } from '@/stores/tokenStore/type/AssetsToken'
import useBackup from '@/pages/login/hooks/useBackup'
import { WalletType } from '@/stores/tokenStore/type/BTCToken'
import { getChainByChainIdV2 } from '@/stores/walletStore/utils'
import { CustomListInfo } from '@/api/type'
import ReceiveTabButtons from '../components/ReceiveTabButtons'
import { BackButton } from '@vkruglikov/react-telegram-web-app'
import { TToast } from '@/components/tmd'
import rampStore from '@/stores/rampStore'
import { selectGasUSDTokensOnramp } from '@/stores/rampStore/utils'
import tokenStore from '@/stores/tokenStore'
import useChainsStore from '@/stores/chainsStore/hooks/useChainsStore'

export function ReceivePageComponent(props: {
  chain?: string
  address?: string
  wtype?: string
  image?: string
  tokenSymbol?: string
  symbol?: string
  onBack?: () => void
}) {
  // from home need props
  const {
    chain: chainIdStr,
    address: tokenAddress,
    wtype: paramWalletType
  } = props

  const navigate = useNavigate()
  const qrRef = useRef<any>(null)
  const { user } = useLoginInfo()
  // from market and other page need params
  const chainId = chainIdStr || '-1'
  const tokens = walletStore.walletTokens
  // const { data: selectedTokensQuery } = useSelectedTokens()
  // const { data: trendingTokensQuery } = useTrendingTokens()

  const { getChainNameById } = useChains()
  const { chains } = useChainsStore()
  const { data, isLoading } = useTokenSearch(
    tokenAddress,
    chainId ? getChainNameById(Number(chainId)) || '' : ''
  )
  const { setOpen, component } = useBackup({
    onSkip: () => {
      setOpen(false)
    }
  })

  useEffect(() => {
    if (!user?.email) {
      setOpen(true)
    }
  }, [])

  useEffect(() => {
    if (paramWalletType && btcTypeMaps[paramWalletType]) {
      walletStore.btcWalletTypeActions(paramWalletType as WalletType)
    }
  }, [paramWalletType])

  const trendingTokens: AssetsToken[] | undefined = data?.map((item) => {
    const customToken: CustomListInfo = {
      ID: 0,
      chain_id: Number(chainId),
      created_at: 0,
      decimals: item.decimals,
      image: item.imageUrl,
      market_cap: Number(item?.marketCapUsd),
      name: item.symbol,
      price: Number(item?.priceUsd),
      price_change_h24: item?.priceChangeH24,
      symbol: item.symbol,
      token: item.address,
      uid: 0
    }
    const assets: AssetsToken = {
      isNative: !item.address,
      isToken: !!item.address,
      chainId: Number(chainId),
      decimals: item.decimals,
      symbol: item.symbol,
      name: item.symbol,
      address: item.address,
      balance: '0',
      price: Number(item.priceUsd),
      image: item.imageUrl,
      source: 'market',
      id: item.groupId,
      formatted: '-',
      whiteToken: undefined,
      customToken
    }

    return assets
  })

  const { getAddressAsChainId } = useLoginInfo()

  const tokensCalc = [...tokens, ...(trendingTokens ?? [])]

  const token = tokensCalc?.find((token) => {
    if (tokenAddress !== zeroAddress) {
      return (
        token.address?.toLocaleUpperCase() ===
          tokenAddress?.toLocaleUpperCase() && String(token.chainId) == chainId
      )
    }
    return String(token.chainId) == chainId && !token.address
  })

  const address = getAddressAsChainId({ chainId: Number(chainId) })
  const toastId = useRef<RId | null>(null)

  const onCopy = () => {
    if (toastId.current) {
      TToast.clear()
    } else {
      toastId.current = TToast.success('Copied to clipboard successfully', {
        onClose: () => {
          toastId.current = null
        }
      })
    }
  }

  const chainName =
    getChainByChainIdV2({
      chainId: Number(chainId),
      chains
    })?.name || ''
  const toSelectToken = () => {
    navigate('/receive/select-token')
  }

  const onrampFlag = useMemo(() => {
    const support = rampStore.supportCryptoList.filter((item) => item.buyEnable)
    const sortprice = selectGasUSDTokensOnramp(
      tokenStore.tokenList,
      support,
      walletStore.walletTokens
    )
    const find = sortprice.find(
      (i) =>
        i.address.toLowerCase() === (token?.address || '').toLowerCase() &&
        i.chainId === token?.chainId
    )
    return !!find
  }, [token?.address, token?.chainId])

  const ChainTag = () => {
    let tagName = chainName
    if (chainId == '0' && token?.symbol === 'BTC') {
      tagName = btcTypeMaps?.[paramWalletType as keyof typeof btcTypeMaps] || ''
    }
    return tagName ? (
      <div
        className={` rounded-[5px]  bg-bg3 px-[4px] py-[3px] text-xs text-t2`}
      >
        {tagName}
      </div>
    ) : (
      <></>
    )
  }

  return (
    <TContainer className="flex flex-col">
      <BackButton onClick={() => navigate(-1)} />
      {component}
      {isLoading ? (
        <div className="flex size-full items-center justify-center">
          <Loading />
        </div>
      ) : (
        <>
          <div
            className={`flex w-full flex-1 flex-col items-center gap-[24px]`}
          >
            <div className="mt-[14px] flex w-full flex-1 flex-col items-center">
              <div
                className="mb-[8px] flex items-center rounded-full bg-bg3 p-[8px]"
                onClick={toSelectToken}
              >
                <TTokenImage
                  image={token?.image || ''}
                  size={20}
                  symbol={token?.symbol?.toUpperCase() || ''}
                />
                <span className="ml-[4px] mr-[8px] max-w-[160px] truncate text-sm text-t1">
                  {token?.symbol?.toUpperCase() || ''}
                </span>
                <TIcon
                  name="tg_wallet_pull-down2"
                  fontSize="20px"
                  className="mt-[-2px] text-t1"
                />
              </div>
              <div className="flex w-full flex-col items-center">
                <div
                  className={`relative mb-[6px] mt-[8px] flex size-[250px] items-center justify-center bg-white `}
                  ref={qrRef}
                >
                  <QRCode value={address || ''} className={`size-[90%]`} />
                </div>
                <div className="text-xs text-t3">
                  Only supports receiving{' '}
                  <span className="text-medium text-sm text-t1">
                    {chainName}
                  </span>{' '}
                  network assets
                </div>
                <div className="mt-[36px] flex w-4/5 justify-center gap-[8px]">
                  <ReceiveTabButtons token={token} onrampFlag={onrampFlag} />
                </div>
              </div>

              <div className="mb-[10px] flex w-full flex-1 flex-col justify-end">
                <div className="w-full rounded-lg py-[16px]">
                  <div className={`flex w-full flex-col  gap-[8px]`}>
                    {
                      <div className="flex items-center">
                        <div className={` flex items-center gap-[4px]`}>
                          {token && (
                            <div
                              className={`max-w-[160px] truncate text-xl font-medium leading-[21px] text-t1`}
                            >
                              {token.symbol.toLocaleUpperCase()}
                            </div>
                          )}
                          <ChainTag />
                        </div>
                      </div>
                    }

                    <div
                      className={`flex w-full items-center gap-[4px] text-lg leading-[20px]`}
                    >
                      <div className="min-w-0 flex-1 break-all text-sm text-t3">
                        {address}
                      </div>

                      <Copy
                        onCopy={onCopy}
                        text={address || ''}
                        className="ml-[18px] rounded-full border-[0.5px] border-l1 px-[18px] py-[8px] text-t2"
                      />
                    </div>
                  </div>
                </div>

                <div className={`mt-[8px] flex w-full items-center gap-3`}>
                  <CopyToClipboard text={address || ''} onCopy={onCopy}>
                    <Button
                      block
                      size={`large`}
                      // theme="ghost"
                      className="rounded-[8px] bg-primary font-normal leading-[1.3] text-white"
                    >
                      Copy Address
                    </Button>
                  </CopyToClipboard>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </TContainer>
  )
}

export default function Receive() {
  const {
    chain: chainIdStr,
    address: tokenAddress,
    wtype: paramWalletType
  } = useParams()

  const navigate = useNavigate()

  usePageSetting({
    navBar: {
      show: false,
      // title: `Receive ${symbol.toLocaleUpperCase()}`,
      back: true,
      onBack: () => navigate(-1)
    }
  })

  return (
    <ReceivePageComponent
      chain={chainIdStr}
      address={tokenAddress}
      wtype={paramWalletType}
    />
  )
}
