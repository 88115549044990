import { TButton, TIcon, TIconButton } from '@/components/tmd'
import usePopup from '@/hooks/popup/usePopup'
import { Routers } from '@/router'
import rampStore from '@/stores/rampStore'
import { AssetsToken } from '@/stores/tokenStore/type/AssetsToken'
import classNames from 'classnames'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import CurrentcyBuyCard from './CurrencyBuyCard'
import StarBuyCard from './StarBuyCard'
import configChains from '@/proviers/web3Provider/chains'
import { StarSupportChains } from '@/pages/starForGas/components/StarSwapContent'

export enum ReceiveTabKeys {
  Crypto = 'crypto',
  AddCash = 'addCash',
  Star = 'star'
}

interface ReceiveTabsType {
  icon: React.ReactNode
  key: ReceiveTabKeys
  label: string
}

export default function ReceiveTabButtons({
  token,
  onrampFlag
}: {
  token: AssetsToken | undefined
  onrampFlag: boolean
}) {
  const navigate = useNavigate()
  const receiveTabs: ReceiveTabsType[] = []
  if (onrampFlag) {
    receiveTabs.push({
      icon: <TIcon name="tg_wallet_Card" />,
      key: ReceiveTabKeys.AddCash,
      label: 'Add cash'
    })
  }

  // if (token?.isNative && StarSupportChains.includes(token.chainId)) {
  //   receiveTabs.push({
  //     icon: <TIcon name="tg_wallet_star" className="text-orange" />,
  //     key: ReceiveTabKeys.Star,
  //     label: 'Star'
  //   })
  // }

  if (receiveTabs.length >= 1) {
    receiveTabs.unshift({
      icon: <TIcon name="tg_wallet_Cryptocurrency" />,
      key: ReceiveTabKeys.Crypto,
      label: 'Crypto'
    })
  }

  const clickOnramp = (token: AssetsToken | undefined) => {
    if (!token) return
    rampStore.setSelectedToken(token)
    navigate(Routers.ramp.trade())
  }

  const clickStarBuyGas = (token: AssetsToken | undefined) => {
    if (!token) return
    navigate(
      Routers.star.swap({
        chainId: String(token.chainId || configChains.base.id)
      })
    )
  }

  const { setOpen, component } = usePopup({
    content: (
      <div className="mt-[50px] flex flex-col items-center">
        <CurrentcyBuyCard />
        <TButton
          size="large"
          className="mb-[12px] w-full"
          onClick={() => clickOnramp(token)}
        >
          Continue
        </TButton>
        <TButton
          size="large"
          theme="ghost"
          className="w-full !bg-transparent !text-t1"
          onClick={() => setOpen(false)}
        >
          Cancel
        </TButton>
      </div>
    ),
    showCloseButton: false
  })

  const { setOpen: setOpenStar, component: starComponent } = usePopup({
    content: (
      <div className="mt-[50px] flex flex-col items-center">
        <StarBuyCard classNames="[&_.introduce-img]:size-[150px] mb-[100px]" />
        <TButton
          size="large"
          className="mb-[12px] w-full"
          onClick={() => clickStarBuyGas(token)}
        >
          Continue
        </TButton>
        <TButton
          size="large"
          theme="ghost"
          className="w-full !bg-transparent !text-t1"
          onClick={() => setOpenStar(false)}
        >
          Cancel
        </TButton>
      </div>
    ),
    showCloseButton: false
  })

  return (
    <>
      {component}
      {starComponent}
      {receiveTabs.map((tab) => (
        <TIconButton
          key={tab.key}
          onClick={() => {
            if (tab.key === ReceiveTabKeys.AddCash) {
              if (!token) return
              setOpen(true)
            }
            if (tab.key === ReceiveTabKeys.Star) {
              setOpenStar(true)
            }
          }}
          className={classNames(
            'box-border w-[160px]  border-[1px] border-l1 bg-transparent py-[8px] text-t1',
            { 'border-t1': tab.key === ReceiveTabKeys.Crypto },
            { 'opacity-50': tab.key === ReceiveTabKeys.AddCash && !token }
          )}
        >
          {tab.icon}
          <span className="ml-[4px] text-xs">{tab.label}</span>
        </TIconButton>
      ))}
    </>
  )
}
