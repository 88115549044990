import { useMemo, useState } from 'react'
import { Grid, Toast } from '@/components/tmd'
import { MenuItem } from 'components/MenuItem'
import { CustomListInfo, TrendingToken } from 'api/type'
import { useNavigate } from 'react-router-dom'
import { zeroAddress } from 'viem'
import useChains from '@/stores/tokenStore/hooks/useChains'
import { formatNumber } from '@/utils/number'

import useBuyAndSell from 'hooks/useBuyAndSell'
import { isBtc } from 'utils'
import { Routers } from 'router'
import { checkGiftSupported } from 'pages/gift/actions'
import { useSelectedToken } from 'pages/gift/hooks/useGiftCreateState'
import { AssetsToken } from '@/stores/tokenStore/type/AssetsToken'
import rampStore from '@/stores/rampStore'
import walletStore from '@/stores/walletStore'
import { getChainByChainIdV2 } from '@/stores/walletStore/utils'
import { TIcon } from '@/components/tmd'
import QrCode from '@/pages/task/components/QrCode'
import Join from '@/pages/market/components/Join'
import useTPopup from '@/hooks/popup/usePopup'
import tokenStore from '@/stores/tokenStore'
import { selectGasUSDTokensOnramp } from '@/stores/rampStore/utils'
import { receiveLink } from '@/pages/token/utils'
import { IWeb3ChainType } from '@/proviers/web3Provider/type'
import { usePopupBtcType } from '@/hooks/popup/usePopupBtcType'
import useShareUrl from '@/hooks/useShareUrl'
import useInviteLink from '@/hooks/useInviteLink'
import { useWebApp } from '@vkruglikov/react-telegram-web-app'
import usePosterPortal from '@/hooks/usePosterPortal'
import { swapTokenDefaults } from '@/config/const'
import { mockBtcEvmChainId } from '@/config/btc'
import { mockSolEvmChainId } from '@/config/sol'
import useLoginInfo from '@/hooks/useLoginInfo'
import { getTokenShareMessageId } from '@/api'
import { mockEthEvmChainId } from '@/config/eth'
import { t } from 'i18next'
import classNames from 'classnames'
import useChainsStore from '@/stores/chainsStore/hooks/useChainsStore'

export enum MarketTabsEnum {
  SEND = 'Send',
  RECEIVE = 'Receive',
  CARD = 'Add cash',
  SWAP = 'Swap',
  GIFT = 'Gift',
  REFER = 'Share',
  CHAT = 'Chat'
}

// ----------------------------------------------------------------
type Props = {
  toSwap: () => void
  token: TrendingToken | undefined
  disabled: boolean
  // showItems?: MarketTabsEnum[]
  chainId: number | undefined
  iconClassname?: string
  extraData: Record<string, unknown>
  className?: string
  marketData: Record<string, any>
}

// 暂时先不显示 refer 按钮
const noReferChainIds = [80094] // bera

export function Menus({
  chainId,
  toSwap,
  token,
  disabled = false,
  iconClassname,
  // showItems = defaultTabsShow,
  extraData,
  marketData,
  className
}: Props) {
  const navigate = useNavigate()
  const [_, setSelectedToken] = useSelectedToken()
  const { getChainIdByName } = useChains()
  const webApp = useWebApp()
  const { createPoster, destroyPoster } = usePosterPortal()
  const { user } = useLoginInfo()
  const [isPosterCreating, setPosterCreating] = useState(false)
  const { supportGiftChains, showMenus, chains } = useChainsStore()
  const dataChainId = chainId ? chainId : getChainIdByName(token?.chain)

  const chain = getChainByChainIdV2({
    chainId: dataChainId || -1,
    chains
  })

  const showItems = showMenus(chain)

  const onrampFlag = useMemo(() => {
    const support = rampStore.supportCryptoList.filter((item) => item.buyEnable)
    const sortprice = selectGasUSDTokensOnramp(
      tokenStore.tokenList,
      support,
      walletStore.walletTokens
    )
    const find = sortprice.find(
      (i) =>
        i.address.toLowerCase() === (token?.address || '').toLowerCase() &&
        i.chainId === dataChainId
    )
    return !!find
  }, [token?.address, dataChainId])

  const { setOpen: setReceiveOpen, component: receiveComponent } =
    usePopupBtcType({})
  const { setOpen: setSendOpen, component: sendComponent } = usePopupBtcType({
    routeType: 'send'
  })

  const curToken = useMemo(() => {
    if (!token) return undefined
    const customToken: CustomListInfo = {
      ID: 0,
      chain_id: dataChainId ?? -1,
      created_at: 0,
      decimals: token.decimals,
      image: token.imageUrl,
      market_cap: Number(token?.marketCapUsd),
      name: token.symbol,
      price: Number(token?.priceUsd),
      price_change_h24: token?.priceChangeH24,
      symbol: token.symbol,
      token: token.address,
      uid: 0
    }

    const assets: AssetsToken = {
      isNative: token.isNative,
      isToken: !token.isNative,
      chainId: dataChainId ?? -1,
      decimals: token.decimals,
      symbol: token.symbol.toLocaleUpperCase(),
      name: token.symbol,
      address: token.address,
      balance: '0',
      price: Number(token.priceUsd),
      image: token.imageUrl,
      source: 'market',
      id: `${token.address ?? ''}-${dataChainId}-${token.symbol}`,
      formatted: '-',
      whiteToken: undefined,
      customToken
    }
    rampStore.setSelectedToken(assets)
    return assets
  }, [dataChainId, token])
  const { component: rampComponent, setToken: setTokenRamp } = useBuyAndSell()

  // const { setOpen, component: qrCode } = useTPopup({
  //   showCloseButton: false,
  //   content: <QrCode />
  // })

  const openTgInvite = async () => {
    Toast.show({ icon: 'loading', duration: 0 })
    setPosterCreating(true)
    try {
      const chainId = dataChainId
      const posterImgData = (await createPoster({
        token,
        chainId,
        marketData
      })) as string
      console.log('%c posterImgData', 'color: red', posterImgData)

      if (!posterImgData) return

      const fromChainId =
        ((chainId === mockBtcEvmChainId ? mockEthEvmChainId : chainId) || '') +
        ''

      const toChainId =
        (typeof chainId === 'number' ? chainId.toString() : '') + ''

      let fromCurrency = ''
      let toCurrency = ''

      if (chainId === mockBtcEvmChainId) {
        fromCurrency = swapTokenDefaults[mockEthEvmChainId] || ''
        toCurrency = token?.address || ''
      } else if (!token?.address) {
        fromCurrency = swapTokenDefaults[chainId || mockSolEvmChainId] || ''
        toCurrency = ''
      } else {
        fromCurrency = ''
        toCurrency =
          token?.address ||
          swapTokenDefaults[chainId || mockSolEvmChainId] ||
          ''
      }

      const redirectUrl = `SWAPTOKEN_${fromChainId}-${fromCurrency}-${toChainId}-${toCurrency}`
      const params = {
        image: posterImgData,
        symbol: token?.symbol || '',
        sender_name: user?.tgName || user?.nickname || '',
        sender_id: user?.tgId || '',
        redirect_url: redirectUrl,
        price: (marketData?.lastPrice?.toFixed(2) || 0) + '',
        gain: ((token?.priceChangeH24 || 0).toFixed(2) || 0) + '',
        cap: token?.marketCapUsd
          ? formatNumber(token?.marketCapUsd || '')
          : '-',
        // @ts-ignore
        liquidity: token?.liquidityUsd
          ? formatNumber(token?.liquidityUsd || '')
          : '-'
      }

      console.log('poster-getTokenShareMessageId-params', params)

      const messageId = await getTokenShareMessageId(params)
      Toast.clear()

      if (messageId) {
        console.log('poster-messageId', messageId)
        webApp?.shareMessage?.(messageId)
      }
    } catch (err) {
      console.log('%c poster-create-err', 'color: red', err)
    } finally {
      Toast.clear()
      setPosterCreating(false)
      destroyPoster()
    }
  }

  const { setOpen: setJoinOpen, component: setJoinCompoent } = useTPopup({
    showCloseButton: false,
    fullscreen: true,
    content: <Join />,
    contentClassName: 'flex flex-col items-center overflow-y-auto'
  })

  const iconBaseClass = 'text-t1'
  const iconBaseFontSize = '20px'
  const menus = [
    {
      iconSrc: (
        <TIcon
          name="tg_wallet_sent"
          fontSize={iconBaseFontSize}
          className={iconBaseClass}
        />
      ),
      title: t('tg_wallet_token.icon_item1'),
      disabled: !showItems.includes(MarketTabsEnum.SEND),
      action: () => {
        if (disabled) return
        if (isBtc(dataChainId as number)) {
          setSendOpen(true)
          return
        }
        const urlSearchStr = new URLSearchParams({
          chainId: dataChainId ? dataChainId.toString() : '',
          address: token?.address ?? '',
          symbol: token?.symbol ?? ''
        }).toString()

        navigate(`/send/input-address?${urlSearchStr}`)
        // setChooseSendOpen(true)
      }
    },
    {
      iconSrc: (
        <TIcon
          name="tg_wallet_reception"
          fontSize={iconBaseFontSize}
          className={iconBaseClass}
        />
      ),
      title: t('tg_wallet_token.icon_item2'),
      disabled: !showItems.includes(MarketTabsEnum.RECEIVE),
      action: () => {
        if (disabled) return
        if (isBtc(dataChainId as number)) {
          setReceiveOpen(true)
          return
        }

        navigate(
          receiveLink({
            chainId: dataChainId || '-1',
            address: token?.address ? token?.address : zeroAddress
          })
        )
      }
    },
    {
      iconSrc: (
        <TIcon
          name="tg_wallet_swap"
          fontSize={iconBaseFontSize}
          className={iconBaseClass}
        />
      ),
      title: t('tg_wallet_token.icon_item3'),
      disabled: !showItems.includes(MarketTabsEnum.SWAP),
      action: () => {
        if (disabled) return
        toSwap()
      }
    },
    {
      iconSrc: (
        <TIcon
          name="tg_wallet_red-gift"
          fontSize={iconBaseFontSize}
          className={iconBaseClass}
        />
      ),
      title: t('tg_wallet_token.icon_item4'),
      disabled:
        !showItems.includes(MarketTabsEnum.GIFT) ||
        !checkGiftSupported({
          chains: supportGiftChains,
          chainId: dataChainId
        }),
      action: () => {
        setSelectedToken(curToken)
        navigate(Routers.gift.create(0))
      }
    },
    {
      iconSrc: (
        <TIcon
          name="tg_wallet_friend"
          fontSize={iconBaseFontSize}
          className={iconBaseClass}
        />
      ),
      title: t('tg_wallet_token.icon_item6'),
      disabled: !showItems.includes(MarketTabsEnum.REFER) ||
        noReferChainIds.includes(dataChainId as number) ||
        isPosterCreating,
      action: () => {
        if (disabled) return
        openTgInvite()
      }
    }
    // {
    //   iconSrc: <SwapHomeSvg />,
    //   title: 'Stake',
    //   action: () => {
    //     if (disabled) return
    //     toStake()
    //   }
    // },
    // {
    //   iconSrc: (
    //     <TIcon
    //       name="tg_wallet_chat"
    //       fontSize={iconBaseFontSize}
    //       className={iconBaseClass}
    //     />
    //   ),
    //   title: MarketTabsEnum.CHAT,
    //   action: () => {
    //     // console.log('set open chat.')
    //     setJoinOpen(true)
    //   }
    // }
  ]
  if (onrampFlag) {
    menus.splice(3, 0, {
      iconSrc: (
        <TIcon
          name="tg_wallet_buying"
          fontSize={iconBaseFontSize}
          className={iconBaseClass}
        />
      ),
      title: MarketTabsEnum.CARD,
      disabled:
        !showItems.includes(MarketTabsEnum.CARD) ||
        (!token?.isNative &&
          token?.symbol.toLowerCase() !== 'usdt' &&
          token?.symbol.toLowerCase() !== 'usdc'),
      action: () => {
        if (!curToken) return
        rampStore.setSelectedToken(curToken)
        navigate(Routers.ramp.trade())
      }
    })
  }

  return (
    <div className={classNames('bg-bg1 py-10 ', className)}>
      {/* {stakeComponent} */}
      {receiveComponent}
      {rampComponent}
      {sendComponent}
      {/* {chooseSendComponent} */}
      {/* {qrCode} */}
      {setJoinCompoent}

      <Grid columns={onrampFlag ? 6 : 5} gap={0}>
        {menus.map((m) => (
          <Grid.Item key={m.title} className="flex-1">
            <MenuItem
              disabled={m.disabled}
              icon={m.iconSrc}
              title={m.title}
              onClick={() => m?.action()}
              iconClassname={iconClassname}
            />
          </Grid.Item>
        ))}
      </Grid>
    </div>
  )
}
