import usePopup from './usePopup'
import React, { useEffect, useState } from 'react'
import { checkboxSvg, checkboxActiveSvg, visaSvg, mastercardSvg } from 'assets'
import { Button } from '../components/Button'
import useOTC from './useOTC'
import { createHash } from 'crypto'
import useLoginInfo from './useLoginInfo'
import { Web3Type } from '../proviers/web3Provider/type'
import { mockSolEvmChainId } from '../config/sol'
import usePageOpen from './usePageOpen'
import { AssetsToken } from '@/stores/tokenStore/type/AssetsToken'
import { getChainByToken } from '@/stores/walletStore/utils'
import useChainsStore from '@/stores/chainsStore/hooks/useChainsStore'

export default function useBuyAndSell(config?: {
  onPurchaseCreated?: (event: any) => void
}) {
  const { chains } = useChainsStore()
  const [type, setType] = useState('buy')
  const [token, setToken] = useState<AssetsToken | null>(null)
  const [buyType, setBuyType] = useState('mercuryo') //useState('ramp')
  const { showRamp } = useOTC(config)
  const [uri, setUri] = useState('')
  const chain_id = token?.chainId || token?.chainId
  const chain = chains?.find((c) => {
    if (!chain_id) {
      if (token?.symbol == Web3Type.BTC) {
        return c?.type == Web3Type.BTC
      }
    }
    if (chain_id == mockSolEvmChainId) {
      return c?.type == Web3Type.SOL
    }
    return c.chain?.id == chain_id
  })

  const { user, getRenderAddress } = useLoginInfo()
  const webApp = usePageOpen()
  const onContinue = () => {
    if (!token) return
    if (type == 'buy') {
      if (buyType == 'ramp') {
        showRamp(token)
      } else {
        const address = getRenderAddress(getChainByToken(token, chains))

        //token?.contract || token?.address || ZeroAddress
        const mercuryoSecretKey = import.meta.env.VITE_MERCURYO_SECRET_KEY

        const data = `${address}${mercuryoSecretKey}`
        const bytes = Buffer.from(data, 'utf8')

        const signature = createHash('sha512').update(bytes)?.digest('hex')

        const chainName = token.whiteToken?.ramp_support
          ? token.whiteToken?.ramp_support.split('_')[0]
          : chain?.name?.toLocaleUpperCase()

        const tokenName = token.whiteToken?.ramp_support
          ? token.whiteToken?.ramp_support.split('_')[1]
          : token?.symbol.toLocaleUpperCase()

        // &Currencies=${tokenName}
        // &networks=${chainName}
        const link = `${import.meta.env.VITE_MERCURYO_URL}?widget_id=${
          import.meta.env.VITE_MERCURYO_WIDGET_ID
        }&currency=${tokenName}&network=${chainName}&address=${address}&signature=${signature}&type=buy&fix_currency=true`

        return webApp.openLink(link)
      }
    } else {
      const chainName = token.whiteToken?.mercuryo_support
        ? token.whiteToken?.mercuryo_support.split('_')[0]
        : chain?.name?.toLocaleUpperCase()

      const tokenName = token.whiteToken?.mercuryo_support
        ? token.whiteToken?.mercuryo_support.split('_')[1]
        : token?.symbol.toLocaleUpperCase()

      const link =
        import.meta.env.VITE_MERCURYO_URL +
        `?currency=${tokenName}&networks=${chainName}&type=sell`

      if (window.location.protocol == 'http:') {
        window.location.href = link
      } else {
        return webApp.openLink(link)
      }
    }
  }

  //  token?.ramp_support?.length

  const renderTitle = (
    type === 'buy'
      ? token?.whiteToken?.ramp_support &&
        token?.whiteToken?.ramp_support.length > 0
      : token?.whiteToken?.mercuryo_support &&
        token?.whiteToken?.mercuryo_support.length > 0
  )
    ? token?.symbol.toLocaleUpperCase()
    : 'Asset'

  const { open, setOpen, component } = usePopup({
    title: !uri && (
      <div
        className={`flex w-full items-center justify-start gap-[20px] text-[20px]`}
      >
        <div
          className={`flex cursor-pointer flex-col items-center gap-[6px] ${
            type == 'buy' ? 'font-[800] text-red' : 'font-[600] text-[#121212]'
          }`}
          onClick={() => setType('buy')}
        >
          <div>Buy {renderTitle}</div>
          <div
            className={`h-[2px] w-4/5 rounded-[7px] ${
              type == 'buy' ? 'bg-red' : ''
            }`}
          ></div>
        </div>
        <div
          className={`flex cursor-pointer flex-col items-center gap-[6px] ${
            type == 'sell' ? 'font-bold text-red' : 'font-[600] text-[#121212]'
          }`}
          onClick={() => setType('sell')}
        >
          <div>Sell {renderTitle}</div>
          <div
            className={`h-[2px] w-4/5 rounded-[7px] ${
              type == 'sell' ? 'bg-red' : ''
            }`}
          ></div>
        </div>
      </div>
    ),
    content: uri ? (
      <iframe src={uri} frameBorder={0} className={`h-[70vh] w-full`} />
    ) : (
      <div
        className={`flex max-h-[60vh] min-h-[200px] w-full flex-col justify-between gap-[12px]`}
      >
        <div className={`flex w-full flex-col gap-[12px]`}>
          {/* {type == 'buy' && (
            <div
              className={`box-border flex w-full cursor-pointer items-start gap-[16px] rounded-[16px] ${
                buyType == 'ramp'
                  ? 'border-2 border-[#F21F7F]'
                  : 'border-2 border-[#E3E3E3]'
              } px-[12px] py-[16px]`}
              onClick={() => setBuyType('ramp')}
            >
              <div className={`flex w-[16px] items-center`}>
                <img
                  src={buyType == 'ramp' ? checkboxActiveSvg : checkboxSvg}
                />
              </div>
              <div className={`flex flex-1 flex-col gap-[8px]`}>
                <div className={`flex h-[22px] items-center gap-[8px]`}>
                  <div className={`flex text-xl font-semibold`}>Via Ramp</div>
                  <img src={visaSvg} />
                  <img src={mastercardSvg} />
                </div>
                <div className="text-[rgba(18,18,18,0.60)]">
                  {type == 'buy' ? 'Buy' : 'Sell'} {renderTitle} with your
                  credit card or Apple Pay via Ramp
                </div>
              </div>
            </div>
          )} */}

          <div
            className={`box-border flex w-full cursor-pointer items-start gap-[16px] rounded-[16px]  ${
              buyType == 'mercuryo' || type == 'sell'
                ? 'border-2 border-red'
                : 'border-2 border-neutral4'
            } px-[12px] py-[16px]`}
            onClick={() => setBuyType('mercuryo')}
          >
            <div className={`flex w-[16px] items-center`}>
              <img
                src={
                  buyType == 'mercuryo' || type == 'sell'
                    ? checkboxActiveSvg
                    : checkboxSvg
                }
              />
            </div>
            <div className={`flex flex-1 flex-col gap-[8px]`}>
              <div className={`flex h-[22px] items-center gap-[8px]`}>
                <div className={`flex text-xl font-semibold`}>Via Mercuryo</div>
                <img src={visaSvg} />
                <img src={mastercardSvg} />
              </div>
              <div className="text-[rgba(18,18,18,0.60)]">
                Buy {renderTitle} with your credit card or Apple Pay via
                Mercuryo
              </div>
            </div>
          </div>
        </div>
        <Button onClick={onContinue} size={`large`} block>
          Continue
        </Button>
      </div>
    ),
    showCloseButton: !!uri,
    closeButtonClassName: 'pr-[20px] !h-[80px]',
    containerClassName: uri ? '!p-0' : '',
    contentClassName: uri ? '!pt-0 !mb-0' : '!pt-0'
  })
  useEffect(() => {
    if (!open) {
      setUri('')
      setType('buy')
      setBuyType('mercuryo') //setBuyType('ramp')
    }
  }, [open])

  return {
    open,
    setOpen,
    component,
    setToken: (token: AssetsToken) => {
      setToken(token)
      setOpen(true)
    }
  }
}
