import BtcAddressTypeList from '@/components/BtcAddressTypeList'
import { useSecondaryPage } from '@/components/SecondaryPage'
import { ReceivePageComponent } from '@/pages/token/detail/Receive'
import configChains from '@/proviers/web3Provider/chains'
import tokenStore from '@/stores/tokenStore'
import walletStore from '@/stores/walletStore'
import { useNavigate } from 'react-router-dom'
import { zeroAddress } from 'viem'
import usePopup from './usePopup'

export const usePopupBtcType = ({
  title = 'Select address type',
  afterClose,
  routeType = 'receive',
  secondaryOpen = false
}: {
  title?: string
  afterClose?: () => void
  routeType?: 'receive' | 'send'
  secondaryOpen?: boolean
}) => {
  const secondaryPage = useSecondaryPage()
  const navigate = useNavigate()
  const tokens = tokenStore.tokenList
  const token =
    tokens?.find((item) => item.chainId === configChains.btc.id) || ({} as any)

  const handleClickBtcAddressType = (item: any) => {
    if (routeType === 'send') {
      walletStore.btcWalletTypeActions(item.type)

      const searchParams = {
        chainId: token?.chainId,
        address: token?.address,
        btcAdrType: item.type,
        symbol: token?.symbol
      }
      const urlSearchStr = new URLSearchParams(searchParams).toString()
      navigate(`/send/input-address?${urlSearchStr}`)
    } else {
      if (secondaryOpen) {
        secondaryPage.open({
          showHead: false,
          title: `Receive ${token.symbol.toLocaleUpperCase()}`,
          children: (
            <ReceivePageComponent
              chain={`${token.chainId}`}
              address={token?.address ? token?.address : zeroAddress}
              image={token.image || ''}
              onBack={() => {
                secondaryPage.close(true)
              }}
              tokenSymbol={token.symbol}
              wtype={item.type || ''}
            />
          )
        })
      } else {
        navigate(
          `/token/${token.chainId}/${
            token?.address ? token.address : zeroAddress
          }/${routeType}/${item.type}`
        )
      }
    }
  }

  return usePopup({
    title: title,
    afterClose,
    fullscreen: true,
    content: <BtcAddressTypeList onClick={handleClickBtcAddressType} />
  })
}
