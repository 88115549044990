import React from 'react'
// import { Button } from '@/components/tmd'
import classNames from 'classnames'

// ----------------------------------------------------------------
type Props = {
  icon: React.ReactNode
  title: string
  disabled?: boolean
  onClick?: () => void
  iconClassname?: string
}
export function MenuItem({
  disabled,
  icon,
  title,
  onClick,
  iconClassname
}: Props) {
  return (
    <button
      disabled={disabled}
      className={classNames(
        'size-full border-0 p-0 active:before:hidden bg-bg1',
        disabled ? 'opacity-50' : ''
      )}
      onClick={onClick}
    >
      <div className="flex flex-col items-center justify-center gap-2">
        <div
          className={classNames(
            'flex size-10 items-center justify-center rounded-full bg-bg3',
            iconClassname
          )}
        >
          {icon}
        </div>
        <span className="text-xs text-t1">{title}</span>
      </div>
    </button>
  )
}
