import SkeletonMarket from './Skeleton/Market'
import SkeletonTask from './Skeleton/Task'
import SkeletonSwap from './Skeleton/Swap'
import SkeletonWallet from './Skeleton/Wallet'
import SkeletonAIFeed from './Skeleton/AIFeed'
import SkeletonNormal from './Skeleton/Normal'

export const PageLoading = () => {
  const { pathname } = window.location

  switch (pathname) {
    case '/market':
      return <SkeletonMarket favorite />

    case '/task':
      return <SkeletonTask />

    case '/swap':
      return <SkeletonSwap />

    case '/aiFeed':
    case '/aiFeed/home/favorite':
    case '/aiFeed/home/radar':
      return <SkeletonAIFeed />

    case '/wallet':
    case '/':
      return <SkeletonWallet />

    default:
      return <SkeletonNormal />
  }
}
